import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StringHelperService {
  /**
   * String represents that the data was not entered
   */
  public NA = 'N/A';

  constructor(private datePipe: DatePipe) {
  }

  /**
   * Set date string to 'N/A' if date is:
   * empty, null, 'undefined', '0001-01-01',
   * @param date - date as string in format 'yyyy-MM-dd'
   */
  public getDateToNAifNull(date: string): string | null {
    if (
      date === null ||
      typeof date === 'undefined' ||
      date === '0001-01-01' ||
      date.trim() === ''
    ) {
      return null;
    }
    return date;
  }

  /**
   * Get date for screen readers, or return null
   * @param date date as string in format 'yyyy-MM-dd'
   */
  public getScreenReaderDate(date: string): string | null {
    let screenReaderDate: string;
    if (
      date === null ||
      typeof date === 'undefined' ||
      date === '0001-01-01' ||
      date.trim() === ''
    ) {
      screenReaderDate = null;
    } else {
      screenReaderDate = this.datePipe.transform(date, 'EEEE, MMMM d, y');
    }
    return screenReaderDate;
  }

  /**
   * Get timestamp for screen readers, or return null
   * @param date date as string in format 'yyyy-MM-dd'
   */
  public getScreenReaderTimestamp(date: string): string | null {
    let screenReaderDate: string;
    if (
      date === null ||
      typeof date === 'undefined' ||
      date === '0001-01-01' ||
      date.trim() === ''
    ) {
      screenReaderDate = null;
    } else {
      const d = this.toDate(date);
      screenReaderDate = this.datePipe.transform(
        d,
        'EEEE, MMMM d, y, h:mm:ss a'
      );
    }
    return screenReaderDate;
  }

  /**
   * Converts date string ("2021-02-26 13:18:43") to Date object
   *
   */
  public toDate(dateStr: string): Date {
    const parts = dateStr.split(' '); //parts[0] - date, parts[1] - time
    const d = parts[0].split('-');
    const t = parts[1].split(':');
    return new Date(+d[0], +d[1] - 1, +d[2], +t[0], +t[1], +t[2]);
  }
}
