<div class="dialog-header">
  <h1 mat-dialog-title >{{ 'history.title' | translate }}</h1>
</div>
<div mat-dialog-content>
  <div *ngIf="data.history" class="dialog-message1">
    <div class="table-responsive">
      <!-- multiTemplateDataRows -->
      <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8" aria-label="history">
        <!-- start: oldValue column-->
        <ng-container matColumnDef="oldValue">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="width-percent-40"
          >
            {{ data.headerKey | translate }}
          </th>
          <td mat-cell *matCellDef="let element" >
            <!-- start: if value is date -->
            <ng-template [ngIf]="element['newValueIsDate']">
              <mccss-date-span
                [date]="element['newValueDescription']"
                [date4SR]="element['newValueScreenReader']"
              ></mccss-date-span>
            </ng-template>
            <!-- end: if value is date -->
            <!-- start: if value is NOT date -->
            <ng-template [ngIf]="!element['newValueIsDate']">
              {{ element['newValueDescription'] }}
            </ng-template>
            <!-- end: if value is NOT date -->
          </td>
        </ng-container>
        <!-- end: oldValue column-->

        <!-- start: updatedOn column-->
        <ng-container matColumnDef="updatedOn">
          <th
            mat-header-cell
            *matHeaderCellDef

            class="width-percent-30"
          >
            {{ 'history.columns.updatedOn' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" >
            <!-- {{ element['updatedTimestamp'] }} -->
            <mccss-date-span
              [date]="element['updatedTimestamp']"
              [date4SR]="element['updatedTimestampScreenReader']"
            ></mccss-date-span>
          </td>
        </ng-container>
        <!-- end: updatedOn column-->

        <!-- start: updatedBy column-->
        <ng-container matColumnDef="updateUser">
          <th
            mat-header-cell
            *matHeaderCellDef

            class="width-percent-30"
          >
            {{ 'history.columns.updatedBy' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" >
            {{ element['updateUser'] }}
          </td>
        </ng-container>
        <!-- end: updatedBy column-->

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>
    </div>
    <p *ngIf="data.history === null">
      {{ 'history.noHistoryMsg' | translate }}
    </p>
  </div>
</div>
<div mat-dialog-actions>
  <button
    role="button"
    class="mat-focus-indicator mat-raised-button mat-button-base mat-primary"
    color="primary"
    (click)="confirm()"
    aria-label="OK"
  >
    {{ 'history.okBtn' | translate }}
  </button>
</div>
