import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDividerModule } from "@angular/material/divider";

@Component({
  selector: 'mccss-h2-title',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './h2-title.component.html',
  styleUrls: ['./h2-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class H2TitleComponent {

  @Input()
  id: string;

  @Input()
  title: string

}
