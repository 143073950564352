import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { HistoryService } from '../../../services/history/history.service';
import { HistoryRequest } from '../../../services/history/HistoryRequest';
import { HistoryDialogComponent } from './history-dialog.component';
import { HistoryResponse } from "../../../services/history/HistoryResponse";

@Injectable()
export class HistoryDialogService {
  /**
   * Reference to history dialog component
   */
  dialogRef: MatDialogRef<HistoryDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private history: HistoryService
  ) {}

  /**
   * Opens HistoryDialogComponent
   */
  public open(historyRequest: HistoryRequest, history: HistoryResponse) {
    this.history.getData(historyRequest, history).subscribe((resp) => {
      // when history data is returned from API, open dialog using service
      this.dialogRef = this.dialog.open(HistoryDialogComponent, {
        data: {
          headerKey: historyRequest.headerKey,
          history: resp, // pass history data to dialog
        },
        autoFocus: "dialog"
      });
    })
  }
}
