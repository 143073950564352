import { Component } from '@angular/core';

@Component({
  selector: 'mccss-eocase-owner',
  templateUrl: './eocase-owner.component.html',
  styleUrls: ['./eocase-owner.component.scss'],
})
export class EOCaseOwnerComponent {
  constructor() {
    // NOSONAR
  }
}
