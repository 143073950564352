import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HistoryChangeService } from '../../../../services/history-change/history-change.service';
import { HistoryRequest } from '../../../../services/history/HistoryRequest';
//import { isString } from 'util';
import { HistoryDialogService } from '../../history-dialog/history-dialog.service';
import { HistoryDataType } from '../../../../services/history/HistoryDataType';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HistoryService } from '../../../../services/history/history.service';

@Component({
  selector: 'mccss-eap-row,[mccss-eap-row]',
  templateUrl: './eap-row.component.html',
  styleUrls: ['./eap-row.component.scss'],
})
export class EapRowComponent implements OnInit, OnDestroy {
  /**
   * Input for the component's id that will be used as HTML id attribute.
   */
  @Input() id: string = null;
  /**
   * randomId is autogenerated in case the @Input() id is not provided
   * as attribute to this component. This will guarantee that the html
   * IDs don't repeat in the applicaiton.
   */
  private randomId = this.getRandomIntInclusive(1, 1000000).toString();
  /**
   * UI configuration input for this component.
   */
  @Input() conf?: { fxFlex: {label: number, data: number} } = { fxFlex: { label: 50, data: 50 } };
  /**
   * Label text supplied as component's input
   */
  @Input() label: string;
  /**
   * Data text supplied as component's input
   */
  @Input() data: string;
  /**
   * labelId is computed by using either @Input() id or computed randomId
   * in case @Input() id is not supplied.
   */
  public labelId: string;
  /**
   * dataId is computed by using either @Input() id or computed randomId
   * in case @Input() id is not supplied.
   */
  public dataId: string;
  /**
   * Indicate if the data is date
   */
  @Input() isDate = false;
  /**
   * Text to be used by screen readers only.
   * This text is not visible on the screen
   */
  @Input() dataForScreenReader: string = null;

  @Input() history: HistoryRequest = null;

  /*
   * Destruction observable
   */
  public readonly destroy$: Subject<boolean> = new Subject<boolean>();

  private historyChange: Observable<any>;

  /**
   * Determines if history icon is rendered or not
   */
  public isHistoryVisible = false;

  /**
   * Indicates if current history link was Clicked
   * Used to add remove css class
   */
  public isClicked: boolean = false;

  // constructor(public hds: HistoryDialogService, private hcs: HistoryChangeService, private hs: HistoryService) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.computeID();
    this.trimInputData();
    this.replaceBlankInputDataWithNotApplicable();

    //this.subscribeToHistoryChange();
    //this.subscribeToHistory();
  }

  /**
   * Subscribe to HistoryService 'selectedProperty' and toggle
   * this component instance as current or not
   */
  //  private subscribeToHistory(): void {
  //   if (this.history) {
  //     // subscribe to property for which we currently pull history
  //     this.hs.selectedProperty$
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe(prop => {
  //         // if HistoryRequest of this component instance is found in
  //         // HistoryService.selectedProperty$ value
  //         if (prop.includes(this.history.prop)) {
  //           this.isClicked = true; // set this component as current
  //         } else {
  //           this.isClicked = false; // unset this component as current
  //         }
  //       });
  //   }
  // }

  // public getHistory():void {
  //   // call history api and open dialog with response
  //   console.log('History request for: ' + this.history.prop);
  //   this.hds.open(this.history);
  // }

  // private subscribeToHistoryChange(): void {
  //   if(this.history) {
  //     this.selectHistoryChangeDataType();
  //     //if(this.history.dataType === HistoryDataType.PlanContentApi) { // TODO: remove the if statement!!!
  //       this.historyChange.pipe(takeUntil(this.destroy$)).subscribe(h => {
  //         const x =0;
  //         if(h){
  //           const pathArray = this.history.prop.split('.');
  //           this.traverse(h, pathArray, 0);
  //         }

  //       });
  //     //}
  //   }
  // }

  // private traverse(history: any, path: string[], i: number = 0): any {
  //   const nextPathIdx = i + 1;
  //   if(history[path[i]]) { // if object @ path exists
  //     if((nextPathIdx < (path.length))){ // if next path is not the last
  //       this.traverse(history[path[i]], path, nextPathIdx); // get object @ next path
  //     } else { // if it is last path (we are at the property)
  //       if(history[path[i]] === true) { //if property = true
  //         this.isHistoryVisible = true; // show history ikon
  //       }
  //     }
  //   }
  // }

  // private selectHistoryChangeDataType(): void {
  //   if(this.history.dataType === HistoryDataType.PlanOutcomeApi) {
  //     this.historyChange = this.hcs.planOutcome$;
  //   } else if(this.history.dataType === HistoryDataType.PlanSummaryApi) {
  //     this.historyChange = this.hcs.planSummary$;
  //   } else if(this.history.dataType === HistoryDataType.PlanContentApi) {
  //     this.historyChange = this.hcs.planContent$;
  //   } else if(this.history.dataType === HistoryDataType.PlanHomeApi) {
  //     this.historyChange = this.hcs.planHome$;
  //   } else if(this.history.dataType === HistoryDataType.EoCaseOwnerApi) {
  //     this.historyChange = this.hcs.eoCaseowner$;
  //   }
  // }

  // public getHistory(event: MouseEvent): void {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   // call history api and open dialog with response
  //   console.log(
  //     'HistoryLinkComponent.getHistory() - History request for: ' +
  //       this.history.prop
  //   );
  //   this.hds.open(this.history);
  // }

  // public keyDown(event: KeyboardEvent): void {
  //   if (event.key === 'Enter') {
  //     event.stopPropagation();
  //     event.preventDefault();
  //     this.hds.open(this.history);
  //   }
  // }

  /**
   * Assigns supplied unique id to be used by html id tag attribute.
   * If id is not supplied as input parameter, the function will use
   * autogenerated unique id and assign it to this component's two
   * HTML tags that are used by label span and data span
   */
  private computeID(): void {
    const idPart = this.id ?? this.randomId;
    this.labelId = 'label' + idPart;
    this.dataId = 'data' + idPart;
  }

  private isString(value: any) {
    return typeof value === 'string' || value instanceof String;
  }

  /**
   * Trim the data in order to remove white spacing
   */
  private trimInputData(): void {
    if (
      typeof this.data !== 'undefined' &&
      this.data !== null &&
      this.isString(this.data)
    ) {
      this.data.trim();
    }
  }

  /**
   * Will replace empty strings, undefined with NULL
   */
  private replaceBlankInputDataWithNotApplicable(): void {
    if (
      this.data === '' ||
      this.data === null ||
      typeof this.data === 'undefined'
    ) {
      //this.data = 'N/A';
      this.data = null;
    }
  }

  /**
   * Generates random numbers
   * @param min minimum number to be auto generated
   * @param max maximum number to be auto generated
   */
  private getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }
}
