import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryDialogComponent } from './history-dialog.component';
import { DateSpanModule } from '../shared/date-span/date-span.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    TranslateModule.forChild({
      extend: true
    }),
    DateSpanModule,
    MatButtonModule
  ],
    declarations: [HistoryDialogComponent],
    exports: [HistoryDialogComponent]
})
export class HistoryDialogModule {}
