import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PlanSummaryListComponent } from './plan-summary-list.component';
import { DateSpanModule } from '../../shared/date-span/date-span.module';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [PlanSummaryListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true,
    }),
    MatTableModule,
    DateSpanModule,
  ],
  exports: [PlanSummaryListComponent],
})
export class PlanSummaryListModule {}
