import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HistoryRequest } from "../../../../services/history/HistoryRequest";
import { HistoryDialogService } from "../../history-dialog/history-dialog.service";
import { HistoryDataType } from "../../../../services/history/HistoryDataType";
import { takeUntil } from "rxjs/operators";
import { HistoryChangeService } from "../../../../services/history-change/history-change.service";
import { HistoryService } from "../../../../services/history/history.service";

@Component({
  selector: "mccss-history-link,[mccss-history-link]",
  templateUrl: "./history-link.component.html",
  styleUrls: ["./history-link.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HistoryLinkComponent implements OnInit, OnDestroy {
  /**
   * Holds HistoryRequest object which is used to construct
   * request for HistoryService via HistoryDialogService.open()
   */
  @Input() history: HistoryRequest = null;
  /**
   * Indicates if current history link was Clicked
   * Used to add remove css class
   */
  public isClicked = false;
  /*
   * Destruction observable
   */
  public readonly destroy$: Subject<boolean> = new Subject<boolean>();
  /**
   * Determines if history icon is rendered or not
   */
  public isHistoryVisible = false;
  /**
   * Set to one of the 5 observable response objects from the HistoryChangeService:
   * 1) .planOutcome$
   * 2) .eapPlanSummary$
   * 3) .planContent$
   * 4) .planHome$
   * 5) .eoCaseowner$
   */
  private historyChange: Observable<any>;

  constructor(
    public hds: HistoryDialogService,
    private hcs: HistoryChangeService,
    private hs: HistoryService
  ) {
  }

  ngOnInit(): void {

    this.subscribeToHistoryChange();
    this.subscribeToHistory();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Subscribe to HistoryChangeService and get the history changes
   * as a list.
   */
  private subscribeToHistoryChange(): void {
    if (this.history) {
      this.selectHistoryChangeDataType();
      this.historyChange.pipe(takeUntil(this.destroy$)).subscribe((h) => {
        if (h) {
          const pathArray = this.history.prop.split('.');
          this.traverse(h, pathArray, 0);
        }
      });
    }
  }

  /**
   * Subscribe to HistoryService 'selectedProperty' and toggle
   * this component instance as current or not
   */
  private subscribeToHistory(): void {
    if (this.history) {
      // subscribe to property for which we currently pull history
      this.hs.selectedProperty$
        .pipe(takeUntil(this.destroy$))
        .subscribe((prop) => {
          // if HistoryRequest of this component instance is found in
          // HistoryService.selectedProperty$ value
          if (prop.includes(this.history.prop)) {
            this.isClicked = true; // set this component as current
          } else {
            this.isClicked = false; // unset this component as current
          }
        });
    }
  }

  /**
   * Select specific HistoryChangeService response that is computed
   * by using HistoryChangeRequest object of this component instance
   */
  private selectHistoryChangeDataType(): void {
    if (this.history.dataType === HistoryDataType.PlanOutcomeApi) {
      this.historyChange = this.hcs.planOutcome$;
    } else if (this.history.dataType === HistoryDataType.PlanSummaryApi) {
      this.historyChange = this.hcs.planSummary$;
    } else if (this.history.dataType === HistoryDataType.PlanContentApi) {
      this.historyChange = this.hcs.planContent$;
    } else if (this.history.dataType === HistoryDataType.PlanHomeApi) {
      this.historyChange = this.hcs.planHome$;
    } else if (this.history.dataType === HistoryDataType.EoCaseOwnerApi) {
      this.historyChange = this.hcs.eoCaseowner$;
    }
  }

  /**
   * Hides or shows history icon
   * @param history - history change response object that contains all history flags for all fields
   * @param path - patch parts broken down to array where each element of array signifies a property within 'history change' object
   * @param i - current processing index of the 'path' array
   */
  private traverse(history: any, path: string[], i = 0): any {
    const nextPathIdx = i + 1;
    if (history[path[i]]) {
      // if object @ path exists
      if (nextPathIdx < path.length) {
        // if next path is not the last
        this.traverse(history[path[i]], path, nextPathIdx); // get object @ next path
      } else {
        // if it is last path (we are at the property)
        if (history[path[i]] === true) {
          //if property = true
          this.isHistoryVisible = true; // show history ikon
        }
      }
    }
  }

  public getHistory(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    // call history api and open dialog with response
    this.hds.open(this.history, this.hcs.allFieldsHistoryChanges$.value[this.history.prop]);
  }

  public keyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      this.hds.open(this.history, this.hcs.allFieldsHistoryChanges$.value[this.history.prop]);
    }
  }
}
