import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {Observable} from "rxjs";
import {LoadingService} from "./services/core/loading.service";
import { AppInputServiceService } from "./services/app-input-service/app-input-service.service";



@Component({
  selector: 'mccss-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'sams-eap';
  loading$: Observable<boolean>;
  isLongLoading$: Observable<boolean>;


  constructor(private translate: TranslateService, private loadingService: LoadingService, private inputService: AppInputServiceService) {
    this.loading$ = loadingService.isLoading;
    this.isLongLoading$ = loadingService.isLongLoading;
    this.inputService.loadConfig();
  }

  private setDocumentLanguage(language?: string): void {
    // set 'lang' attribute on <html> tag
    document.documentElement.lang =
      language || this.translate.currentLang || 'en';
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.setDocumentLanguage();
    });
  }
}
