import { Component, Input } from '@angular/core';

@Component({
  selector: 'mccss-date-span',
  templateUrl: './date-span.component.html',
  styleUrls: ['./date-span.component.scss'],
})
export class DateSpanComponent {
  /**
   * Date string
   */
  @Input() date: string;
  /**
   * Date string for Screen Reader
   */
  @Input() date4SR: string;

  constructor() {
    // NOSONAR
  }
}
