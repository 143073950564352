import { Injectable, Injector } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel
} from '@angular/router';
import { filter } from 'rxjs/operators';
import {
  ApplicationInsights,
  IConfiguration
} from '@microsoft/applicationinsights-web';

/**
 * Use this function in the root module in the providers array
 * @param s AzureInsightService instance
 * @param environments environment.ts configurations
 * @returns returns a function with no return value
 */
export function ApplicationInsightsServiceLoader(
  s: AzureInsightService,
  environments: any
) {
  s.configureServices(environments.appInsights.instrumentationKey);
  return () => s.subscribeToRouteEvents();
}

/**
 * Wrapper service for Azure Apploication Insights. This service logs application
 * events, page load times, API calls, and any custom events
 */
@Injectable({
  providedIn: 'root'
})
export class AzureInsightService {
  /**
   * Reference to a Router instance supplied by injector
   */
  private router: Router;
  /**
   * Reference to a AppInsightsService instance supplied by injector
   */
  private azureInsights: ApplicationInsights;
  /**
   * Holds the instrumentation key that is used to access Azure Application Insights service
   */
  private instrumentationKey: string = null;

  /**
   * AzureInsightService constructor
   * @param injector - Injector instance used to inject the Router
   */
  constructor(private injector: Injector) {
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    if (this.azureInsights) {
      this.azureInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.azureInsights) {
      this.azureInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    if (this.azureInsights) {
      this.azureInsights.trackMetric(
        { name: name, average: average },
        properties
      );
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.azureInsights) {
      this.azureInsights.trackException({
        exception: exception,
        severityLevel: severityLevel
      });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.azureInsights) {
      this.azureInsights.trackTrace({ message: message }, properties);
    }
  }

  /**
   * Starts the timer for tracking a page view. Use this instead of trackPageView if you want to control when the page
   * view timer starts and stops, but don't want to calculate the duration yourself. This method doesn't send any telemetry.
   * Call stopTrackPage to log the end of the page view and send the event.
   * @param url The name used to identify the page in the portal. Defaults to the document title.
   */
  public startTrackPage(url: string) {
    this.azureInsights.startTrackPage(url);
  }

  /**
   * Stops the timer that was started by calling startTrackPage and sends the page view telemetry with the specified
   * properties and measurements. The duration of the page view will be the time between calling startTrackPage and stopTrackPage.
   * @param name The name used to identify the page in the portal. Defaults to the document title.
   * @param url A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
   * @param properties Map of string to string: Additional data used to filter pages in the portal. Defaults to empty.
   *                filters: https://docs.microsoft.com/en-us/azure/application-insights/app-insights-api-custom-events-metrics#properties
   * @param measurements Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on the portal.
   *                     Defaults to empty.
   */
  public stopTrackPage(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    this.azureInsights.stopTrackPage(name, url, properties, measurements);
  }

  /**
   * Reserved for future use.
   * Build the properties (string values) for Page tracking to be send as additional data.
   */
  private buildProperties(): { [key: string]: string } {
    const ret: { [name: string]: string } = {};
    return ret;
  }

  /**
   * Reserved for future use.
   * Build the measurements (numeric values) for Page tracking to be send as additional data.
   */
  private buildMeasurements(): { [key: string]: number } {
    return null;
  }

  /**
   * Subscribe to router events in order to mark START and END of page tracking (loading times).
   */
  public subscribeToRouteEvents() {
    if (this.instrumentationKey) {
      this.router = this.injector.get(Router); // inject the router
      this.router.events
        .pipe(filter(e => e instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          this.startTrackPage(event.url); // START URL X Page tracking
        });

      this.router.events
        .pipe(
          filter(
            e => e instanceof NavigationEnd || e instanceof NavigationCancel
          )
        )
        .subscribe((event: NavigationEnd | NavigationCancel) => {
          this.stopTrackPage(
            event.url,
            window.location.href,
            this.buildProperties()
          ); // END URL X Page tracking
        });
    }
  }

  /**
   * Configures and loads application insights service with instrumentation key (if present)
   * @param instrumentKey Application insights instrumentation key
   */
  public configureServices(instrumentKey: string): void {
    if (instrumentKey) {
      this.instrumentationKey = instrumentKey;

      this.azureInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentKey,
          //enableAutoRouteTracking: true, // option to log all route changes,
          overrideTrackPageMetrics: true
        } as IConfiguration
      });
      this.azureInsights.loadAppInsights();
    }
  }
}
