
<ng-container *ngIf="id">
  <h2 [id]="id">
    {{ title }}
  </h2>
</ng-container>

<ng-container *ngIf="!id">
  <h2>
    {{ title }}
  </h2>
</ng-container>

<mat-divider></mat-divider>
