import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { PlanSummaryResponse } from './PlanSummaryResponse';
import { EAPPlanSummary } from './EAPPlanSummary';
import { StringHelperService } from '../shared/StringHelper.service';
import { BaseAPIService } from '../shared/base-api/base-api.service';
import { AppInputServiceService } from '../app-input-service/app-input-service.service';

/**
 * PlanSummaryServiceLoader to be used by importing module (ex: app module).
 * @param s PlanSummaryService injection
 * @param environments environments/environment.ts
 */
export function PlanSummaryServiceLoader(
  s: PlanSummaryService,
  environments: any
) {
  return () => s.setEnvApiConf(environments.api.planSummary);
}

@Injectable({
  providedIn: 'root',
})
export class PlanSummaryService extends BaseAPIService {
  /**
   * Holds PlanSummaryResponse object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planSummaryResponse = new BehaviorSubject<PlanSummaryResponse>(
    PlanSummaryService.init()
  );
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planSummaryResponse$: Observable<PlanSummaryResponse> =
    this._planSummaryResponse.asObservable();
  /**
   * Holds 'selected' EAPPlanSummary object for internal processing ONLY.
   * This property must be private.
   */
  private _selectedPlanSummary = new BehaviorSubject<EAPPlanSummary>(null);
  /**
   * Expose BehaviorSubject _selectedPlanSummary to external subscribers as an Observable
   */
  public selectedPlanSummary$: Observable<EAPPlanSummary> =
    this._selectedPlanSummary.asObservable();

  constructor(
    public http: HttpClient,
    private strHelper: StringHelperService,
    private appInputService: AppInputServiceService
  ) {
    super();
  }

  public static init(): PlanSummaryResponse {
    const ps: PlanSummaryResponse = {
      caseReference: 0,
      eapPlanSummary: [
        {
          isSelected: false,
          clientName: null,

          dateIssued: null,

          issueReason: null,

          formattedAddressData: null,

          creationDate: null,

          templateVersion: null,

          acceptDate: null,

          fileLocation: null,

          fileReference: null,

          dateRejected: null,

          rejectReason: null,

          rejectComments: null,

          dateCanceled: null,

          cancelReason: null,

          cancelComments: null,

          status: null,
        } as EAPPlanSummary,
      ] as EAPPlanSummary[],

      errorResponse: null,
    };
    return ps;
  }

  /**
   * Selects EAPPlanSummary object from the list for UI display purposes
   * @param ps - EAPPlanSummary object to be set as selected (for displaying purposes)
   */
  public selectPlanSummary(ps: EAPPlanSummary): void {
    this._selectedPlanSummary.next(ps);
  }

  /**
   * Gets selected EAPPlanSummary object in the service (value in )
   */
  public getSelectedPlanSummary(): EAPPlanSummary {
    return this._selectedPlanSummary.value;
  }

  /**
   * Unselects EAPPlanSummary object from the list for UI display purposes
   * @param ps - EAPPlanSummary object to be set as selected (for displaying purposes)
   */
  public unselectPlanSummary(): void {
    const ps = PlanSummaryService.init().eapPlanSummary[0];
    this._selectedPlanSummary.next(ps);
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(caseReference: number): string {
    const url = this.useMockResponse
      ? this.conf.mockURL
      : this.conf.path + '/' + caseReference.toString();
    return url;
  }

  /**
   * Load data from back end
   * @param apiPath - path to GET method URL
   */
  getData(caseReference: number) {
    this.http
      .get<PlanSummaryResponse>(this.computeApiURL(caseReference), {
        headers: this.getHttpOptions(),
        withCredentials: true,
      })
      .pipe(
        first(), // obtain only 1st emitted data
        map((data) => {
          this.massageData(data, caseReference);
          this._planSummaryResponse.next(data);
        })
      )
      .subscribe();
  }

  /**
   * Checks if there is a response and resposne has required properties
   * @param psr @see {PlanSummaryResponse} API response
   * @returns true if response has data; false otherwise
   */
  private responseHasData(psr: PlanSummaryResponse): boolean {
    return psr && psr.eapPlanSummary && psr.descriptionMap ? true : false;
  }

  /**
   * Massages data for each item in the eapPlanSummary[] property by using
   * keys from each EAPPlanSummary item and looking up their description in the
   * PlanSummaryResponse.descriptionMap property
   * @param psr PlanSummaryResponse object
   */
  private massageData(psr: PlanSummaryResponse, caseReference: number): void {
    if (this.responseHasData(psr)) {
      psr.caseReference = caseReference;
      psr.eapPlanSummary.forEach((v, i) => {
        v.caseReference = psr.caseReference;
        v.uiRowIdx = i;
        this.massagePrimitiveData(v, psr.descriptionMap);
        this.massageDates(v);
        // this.patchPrimaryKey(v);
      });
    } else {
      throw new Error(this.conf.appError['apiResponseMissingData']);
    }
  }

  /**
   * Massages all dates and generates dates as readable strings for screen readers
   * @param v @see {EAPPlanSummary} object
   */
  private massageDates(v: EAPPlanSummary): void {
    this.massageDateCanceled(v);
    this.massageDateIssued(v);
    this.massageDateRejected(v);
    this.massageAcceptDate(v);
    this.massageCreationDate(v);
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPPlanSummary} object
   */
  private massageDateCanceled(v: EAPPlanSummary): void {
    v.dateCanceled = this.strHelper.getDateToNAifNull(v.dateCanceled);
    v.dateCanceledScreenReader = this.strHelper.getScreenReaderDate(
      v.dateCanceled
    );
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPPlanSummary} object
   */
  private massageDateIssued(v: EAPPlanSummary): void {
    v.dateIssued = this.strHelper.getDateToNAifNull(v.dateIssued);
    v.dateIssuedScreenReader = this.strHelper.getScreenReaderDate(v.dateIssued);
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPPlanSummary} object
   */
  private massageDateRejected(v: EAPPlanSummary): void {
    v.dateRejected = this.strHelper.getDateToNAifNull(v.dateRejected);
    v.dateRejectedScreenReader = this.strHelper.getScreenReaderDate(
      v.dateRejected
    );
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPPlanSummary} object
   */
  private massageAcceptDate(v: EAPPlanSummary): void {
    v.acceptDate = this.strHelper.getDateToNAifNull(v.acceptDate);
    v.acceptDateScreenReader = this.strHelper.getScreenReaderDate(v.acceptDate);
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPPlanSummary} object
   */
  private massageCreationDate(v: EAPPlanSummary): void {
    v.creationDate = this.strHelper.getDateToNAifNull(v.creationDate);
    v.creationDateScreenReader = this.strHelper.getScreenReaderDate(
      v.creationDate
    );
  }

  /**
   * Maps IDs/Codes to string descriptions stored in the descriptionMap property.
   * @param v @see {EAPPlanSummary} object
   * @param m {{ [key: string]: any }} - description map with lookup values
   */
  private massagePrimitiveData(
    v: EAPPlanSummary,
    m: { [key: string]: any }
  ): void {
    v.statusDescription = m[v.status];
    v.issueReasonDescription = m[v.issueReason];
    v.cancelReasonDescription = m[v.cancelReason];
    v.rejectReasonDescription = m[v.rejectReason];
  }
}
