import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable, observable, of, throwError } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators';
import { CaseWorkerProfile } from './CaseWorkerProfile';

/**
 * PlanSummaryServiceLoader to be used by importing module (ex: app module).
 * @param s PlanSummaryService injection
 * @param environments environments/environment.ts
 */
export function CaseworkerServiceLoader(
  s: CaseworkerService,
  environments: any
) {
  return () => s.setApiUrl(environments.api.caseworker.path);
}

@Injectable({
  providedIn: 'root',
})
export class CaseworkerService {
  /**
   * Indicates if the service should call real API or use mock data that is provided
   * by a .json file in the /assets/services/mock folder
   */
  public useMockResponse = false;
  /**
   * Mock URL used for development purposes. The service will use a static mock
   * response stored in the .json file located in the /assets/services/mock folder
   */
  public mockURL = '/assets/services/mock/caseWorkerResponse.json';
  /**
   * Back end API URL; initialized using setApiUrl(url: string) by
   * PlanHomeService(s: PlanHomeService, environments: any)
   */
  private apiURL: string;
  /**
   * Holds PlanHomeResponse object for internal processing ONLY.
   * This property MUST be private.
   */
  private _caseWorkerResponse = new BehaviorSubject<CaseWorkerProfile>(
    CaseworkerService.init()
  );
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public caseWorkerResponse$: Observable<CaseWorkerProfile> =
    this._caseWorkerResponse.asObservable();

  constructor(public http: HttpClient) {
  }

  /**
   * Returns empty PlanHomeResponse object
   */
  public static init(): CaseWorkerProfile {
    const ps: CaseWorkerProfile = {} as CaseWorkerProfile;
    return ps;
  }

  public getLastCaseWorkerResponseValue(): CaseWorkerProfile {
    return this._caseWorkerResponse.value;
  }

  public setApiUrl(url: string): void {
    this.apiURL = url;
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(caseReference: number): string {
    const url = this.useMockResponse
      ? this.mockURL
      : this.apiURL + '/' + caseReference.toString();
    return url;
  }

  /**
   * Load data from back end
   * @param apiPath - path to GET method URL
   */
  getData(caseReference: number): void {
    this.http
      .get<CaseWorkerProfile>(this.computeApiURL(caseReference), {
        headers: this.getHttpOptions(),
        withCredentials: true,
      })
      .pipe(
        first(), // obtain only 1st emitted data
        map((data) => {
          this._caseWorkerResponse.next(data);
        })
      )
      .subscribe();
  }

  /**
   * Returns newly created httpOptions object that is used by POST request to back-end.
   * Object contains HttpHeaders for application/json, etc.
   */
  private getHttpOptions(): any {
    const timestamp =
      new Date().toISOString().replace('T', ' ').replace('Z', '') + '000';
    const headers = new HttpHeaders()
      .set('x-bil-source-system', 'BIL')
      .set('x-bil-target-system', 'CAMS')
      .set('x-bil-req-timestamp', timestamp);

    return headers;
  }
}
