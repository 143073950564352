import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';
import { share, first, tap, skip } from 'rxjs/operators';
import { AppInputServiceService } from '../app-input-service/app-input-service.service';
import { CaseworkerService } from './caseworker.service';
import { CaseWorkerProfile } from './CaseWorkerProfile';

@Injectable({providedIn: 'root'})
export class CaseworkerResolver
  implements Resolve<Observable<CaseWorkerProfile>>
{
  /**
   * indicates if resolve() was already executed
   */
  private wasInvoked = false;
  /**
   * ActivatedRouteSnapshot object
   */
  private route: ActivatedRouteSnapshot;

  constructor(
    private cws: CaseworkerService,
    private appInputService: AppInputServiceService
  ) {
  }

  /**
   * IMPORTANT: Can't cancel navigation. bug in Angular https://github.com/angular/angular/issues/21961  !!!!!!!!!!!!!!!!
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CaseWorkerProfile> {
    if (this.wasInvoked) {
      // if the resolver was already executed
      return null; // stop further execution
    }

    // tell the servides to use mock response if needed for development
    this.cws.useMockResponse = this.appInputService.useMockResponse;
    this.cws.getData(this.appInputService.caseReference); // call back-end API

    return this.cws.caseWorkerResponse$.pipe(
      share(),
      skip(1), // skip initial value
      first(), // obtain 2nd value emited (and don't take any other emittions)
      tap(() => {
        this.wasInvoked = true; // stop resolver from executing again when user navigates to the same route again
      })
    );
  }
}
