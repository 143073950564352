import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { EAPHomeComponent } from './eaphome.component';

import { EapRowModule } from "@shared/eap-row/eap-row.module";
import { MatRowTwoColumnsModule } from "@shared/mat-row-two-columns/mat-row-two-columns.module";
import { MatDividerModule } from "@angular/material/divider";
import { H2TitleComponent } from '@shared/h2-title/h2-title.component';

@NgModule({
  declarations: [EAPHomeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true
    }),
    EapRowModule,
    MatRowTwoColumnsModule,
    MatDividerModule,
    H2TitleComponent
  ],
  exports: [EAPHomeComponent],
})
export class EAPHomeModule {}
