<section
  class="container"
  role="region"
  aria-labelledby="eapEmploymentOutcomeTitle"
>
  <mccss-h2-title [id]="'eapEmploymentOutcomeTitle'" [title]="'eapHome.homeEmploymentOutcome' | translate"></mccss-h2-title>
  <div
    class="form-control-container"
    *ngIf="(phs.planHomeResponse$ | async).eapHome as phr"
  >
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.outcomeAchievedDescription' | translate"
        [data]="phr.outcomeAchievedDescription"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.outcomeAchieved',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.outcomeAchievedDescription'
        }"
        data-e2e="eap-home-outcome-history"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.empOutcomeNumberOfHoursWorked' | translate"
        [data]="phr.empOutcomeNumberOfHoursWorked"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.empOutcomeNumberOfHoursWorked',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.empOutcomeNumberOfHoursWorked'
        }"
        data-e2e="eap-home-outcome-worked-hr-history"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>

    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.empOutcomeNocCDDescription' | translate"
        [data]="phr.empOutcomeNocCDDescription"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.empOutcomeWagePerHourAmount' | translate"
        [data]="phr.empOutcomeWagePerHourAmount"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.empOutcomeWagePerHourAmount',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.empOutcomeWagePerHourAmount'
        }"
        data-e2e="eap-home-outcome-wage-per-hour-history"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
  </div>
</section>
