import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'mccss-h1-title',
  templateUrl: './h1-title.component.html',
  styleUrls: ['./h1-title.component.scss'],
})
export class H1TitleComponent implements AfterViewInit {
  /**
   * ngx translate key
   */
  @Input() title: string;
  @Input() id: string;
  @ViewChild('pageTitle') t1Ref: ElementRef;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.t1Ref.nativeElement.focus();
    this.t1Ref.nativeElement.scrollIntoView();
  }
}
