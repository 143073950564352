import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {URLSearchParams} from "../browser-patch/UrlSearchParams";
import {Sams2CamsResponse} from "../cams2sams/Sams2CamsResponse";
import {map, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {EnrichedCaseNote} from "../cams2sams/EnrichedCaseNote";


export function AppInputServiceLoader(
  s: AppInputServiceService,
  environments: any
) {
  s.setActiveEAPCodes(environments.api.cams2sams.activeEAPCodes);
  s.setNgxTranslateKeyErrorNoEAP(
    environments.api.cams2sams.customError.ngxTranslateKeyErrorNoEAP
  );
  s.setNgxTranslateKeyErrorNoAllEAPClosed(
    environments.api.cams2sams.customError.ngxTranslateKeyErrorAllEAPClosed
  );
  s.checkIfMockResponseWillBeUsed();
  return () => s.setApiUrl(environments.api.cams2sams.path);
}

@Injectable()
export class AppInputServiceService {
  /**
   * Indicates if the service should call real API or use mock data that is provided
   * by a .json file in the /assets/services/mock folder
   */
  public useMockResponse = false;
  /**
   * Mock URL used for development purposes. The service will use a static mock
   * response stored in the .json file located in the /assets/services/mock folder
   */
  public mockURL = "/assets/services/mock/sams2camsResponse.json";
  /**
   * Used by PlanSummaryService, PlanContentService, PlanHomeService (API)
   */
  public caseReference: number;
  /**
   * Used by Outcome Service (API)
   */
  public camsReferenceId: number;
  /**
   * comes via query string
   */
  public samsMemberId: number;

  /**
   * Back end API URL; initialized using setApiUrl(url: string) by
   * ***ServiceLoader() function
   */
  private apiURL: string;

  /**
   * Holds ngxTranslation key for error that is displayed when
   * no EAP record is found for a given samsMemberID
   */
  private ngxTranslateKey_ErrorNoEAP: string;

  /**
   * Holds ngxTranslation key for error that is displayed when
   * all EAP records are closed for a given samsMemberID
   */
  private ngxTranslateKey_ErrorAllEAPClosed: string;

  public caseReferenceNumber$ = new BehaviorSubject<string>("");

  // public eapCaseList$ = new BehaviorSubject<Array<EapCaseDetails>>([]);

  public caseNoteList : Array<EnrichedCaseNote> = [];

  private activeEAPCodes: string[] = [];

  constructor(public http: HttpClient) {
  }

  /**
   * Sets base API URL
   */
  public setApiUrl(apiUrl: string): void {
    this.apiURL = apiUrl;
  }

  /**
   * Checks if 'useMockResponse' query string variable present and set to true,
   * in order to instruct the service to use .json mock file instead
   * of the real call to the API.
   */
  public checkIfMockResponseWillBeUsed(): void {
    // check if query string variable 'useMockResponse' exists and is set to true
    this.useMockResponse = !!+URLSearchParams.getParam("useMockResponse");
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(samsMemberId: number): string {

    const url = this.useMockResponse
      ? this.mockURL
      : this.apiURL + "/" + samsMemberId.toString() + "?codeLookupLanguage=en";
    return url;
  }

  /**
   * Sets ngxTranslation Key for the error message that is shown when
   * there are no EAP records for given samsMemberID
   * @param ngxTranslationKey string
   */
  public setNgxTranslateKeyErrorNoEAP(ngxTranslationKey: string): void {
    this.ngxTranslateKey_ErrorNoEAP = ngxTranslationKey;
  }

  /**
   * Sets ngxTranslation Key for the error message that is shown when
   * all EAP cases are closed for given samsMemberID
   * @param ngxTranslationKey string
   */
  public setNgxTranslateKeyErrorNoAllEAPClosed(
    ngxTranslationKey: string
  ): void {
    this.ngxTranslateKey_ErrorAllEAPClosed = ngxTranslationKey;
  }

  /**
   * Builds necessary headers to make a call to back end api
   */
  private getHttpOptions(): any {
    const timestamp =
      new Date().toISOString().replace("T", " ").replace("Z", "") + "000";
    const headers = new HttpHeaders()
      .set("x-bil-source-system", "BIL")
      .set("x-bil-target-system", "CAMS")
      .set("x-bil-req-timestamp", timestamp);
    return headers;
  }

  /**
   * Extracts query string param 'samsMemberID' and sets local property
   * with the value
   */
  private setSamsMemberId(): number {
    this.samsMemberId = +URLSearchParams.getParam("samsMemberID");
    return this.samsMemberId;
  }

  /**
   * makes a call to back end API to get the data
   */
  loadConfig() {
    const samsMemberId = this.setSamsMemberId();
    this.http
      .get<Sams2CamsResponse>(this.computeApiURL(samsMemberId), {
        headers: this.getHttpOptions(),
        withCredentials: true
      }).pipe(
      map((resp) => {
        const activeCaseRefNum = this.massageData(resp)
        this.getCaseNoteList(resp, activeCaseRefNum);
        return activeCaseRefNum;
      }),
      tap(refNum => this.caseReferenceNumber$.next(refNum))
    ).subscribe();

  }

  public getCaseNoteList(camsResp: Sams2CamsResponse, activeCaseRefNumber: string) {

    if (camsResp.samsTocamsCaseList.eapCaseDetailsList
      .find(predicate => predicate.caseReference === activeCaseRefNumber)
      ?.caseNote) {
      this.caseNoteList = camsResp.enrichedCaseNotes
      this.caseNoteList.forEach(value  => {
        value.caseNote.priorityDescription = camsResp.descriptionMap[value.caseNote.priority]
        value.caseNote.typeDescription = camsResp.descriptionMap[value.caseNote.type]
      })
    }


  }

  /**
   * Tries to find the case reference to be used by this application
   * @param d Sams2CamsResponse
   */
  public massageData(d: Sams2CamsResponse): string {
    const isActiveCaseFound = this.findActiveCase(d);
    if (!isActiveCaseFound) {
      const isClosedCaseFound = this.findFirstClosedCase(d);
      if (isClosedCaseFound) {
        // if closed case found
        throw new Error(this.ngxTranslateKey_ErrorAllEAPClosed);
      } else {
        //if no active or open or closed case found
        throw new Error(this.ngxTranslateKey_ErrorNoEAP);
      }
    }
    return isActiveCaseFound;
  }

  /**
   * Searches the samsTocamsCaseList.eapCaseDetailsList for the
   * first available case with status closed (statusCD === 'CS3').
   * If such record is found, return true or false otherwise
   * @param d Sams2CamsResponse
   */
  public findFirstClosedCase(d: Sams2CamsResponse): boolean {
    let isCaseFound = false;
    for (const v of d.samsTocamsCaseList.eapCaseDetailsList) {
      if (v.statusCD === "CS3") {
        isCaseFound = true;
        break;
      }
    }
    return isCaseFound;
  }

  /**
   * Searches the samsTocamsCaseList.eapCaseDetailsList for the
   * first available case with status Active or Open (statusCD === 'CS1' or 'CS4').
   * If such record is found, the caseReference is set to local
   * properties of this service.
   * @param d Sams2CamsResponse
   */
  public findActiveCase(d: Sams2CamsResponse): string {
    for (const v of d.samsTocamsCaseList.eapCaseDetailsList) {
      const statusCode = this.activeEAPCodes.find(code => code === v.statusCD.toLowerCase());
      if (statusCode) {
        this.caseReference = +v.caseReference;
        this.camsReferenceId = this.caseReference;
        return v.caseReference;
      }
    }
    return "";
  }

  setActiveEAPCodes(activeEAPCodes: string) {
    this.activeEAPCodes = !!activeEAPCodes
      ? activeEAPCodes.split(",").filter(c => c.trim().length > 0).map(c => c.trim().toLowerCase())
      : [];
  }
}
