import { Component, OnInit } from '@angular/core';
import { AppInputServiceService } from '../../../services/app-input-service/app-input-service.service';
import { PlanHomeService } from '../../../services/plan-home/plan-home.service';
import { HistoryDataType } from '../../../services/history/HistoryDataType';
import { PropName } from '../../../services/history/PropName';

@Component({
  selector: 'mccss-eap-header',
  templateUrl: './eap-header.component.html',
  styleUrls: ['./eap-header.component.scss'],
})
export class EapHeaderComponent {
  /**
   * Expose enum to template
   */
  historyDataType = HistoryDataType;
  /**
   * Expose enum to template
   */
  propName = PropName;
  constructor(
    public phs: PlanHomeService,
    public ais: AppInputServiceService
  ) {}
}
