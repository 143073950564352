import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable, tap, throwError, timeout} from 'rxjs';
import {LoadingService} from "../core/loading.service";
import {catchError, map} from "rxjs/operators";


@Injectable()
export class HttpRequestLoadingInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(() => this.loadingService.setLoading(request.url)),
      map((evt: HttpEvent<unknown>) => {
        if (evt instanceof HttpResponse) {
          this.loadingService.removeLoading(request.url);
        }
        return evt;
      }),
      catchError((err) => {
        this.loadingService.emptyLoading();
        return throwError(err);
      })
    );
  }
}
