<section class="container" role="region" aria-labelledby="eapHomePageTitle">
  <mccss-h2-title [id]="'eapHomePageTitle'"
                  [title]="'eapHome.title' | translate">

  </mccss-h2-title>
  <div
    class="form-control-container"
    *ngIf="(phs.planHomeResponse$ | async).eapHome as phr"
  >
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.concernRoleName' | translate"
        [data]="phr.concernRoleName"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.referInDescription' | translate"
        [data]="phr.referInDescription"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.userFullName' | translate"
        [data]="phr.userFullName"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.totalSupports' | translate"
        [data]="phr.totalSupports"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.providerName' | translate"
        [data]="phr.providerName"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.employerAtEntrance' | translate"
        [data]="phr.employerAtEntrance"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.employerAtEntrance',
          caseRef: phr.caseReference,
          headerKey: 'eapHeader.employerAtEntrance' | translate
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>

    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.segStream' | translate"
        [data]="phr.segStream"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.segStream',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.segStream'
        }"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.employerAtOutcome' | translate"
        [data]="phr.employerAtOutcome"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.employerAtOutcome',
          caseRef: phr.caseReference,
          headerKey: 'eapHeader.employerAtOutcome' | translate
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.reasonCode' | translate"
        [data]="phr.reasonCode"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.reasonCode',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.segStream'
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
  </div>
</section>
