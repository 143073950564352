<ng-template [ngIf]="this.date === null">
  <span aria-hidden="true">{{ 'generic.notApplicable' | translate }}</span>
  <span class="sr-only" aria-hidden="false">
    {{ 'generic.dataNotEntered' | translate }}
  </span>
</ng-template>
<!-- render end: if date is not entered -->

<!-- render start: if date is entered and screen reader date passed -->
<ng-template [ngIf]="this.date4SR">
  <span aria-hidden="true">{{ this.date }}</span>
  <span class="sr-only" aria-hidden="false">{{ this.date4SR }}</span>
</ng-template>
<!-- render end: if date is entered and screen reader date passed -->
