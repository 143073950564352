<section class="container" role="region" attr.aria-labelledby="caseNoteHistoryId">
  <mccss-h2-title id="caseNoteHistoryTitleId{{caseNoteId}}" [title]="'caseNote.columns.noteHistory' | translate"></mccss-h2-title>
  <div class="form-control-container">
    <div *ngFor="let parsedNoteHistory of (parsedNoteHistories); let i = index">
      <div class="eap-two-columns">
        <div class="eap-two-column" style="padding: 1.1rem 0 1.1rem 0;" >
          <span [innerHTML]="parsedNoteHistory.author" id="parsedNoteHistory.author.id{{caseNoteId}}{{i}}"></span>
          &nbsp;On
          <span [innerHTML]="parsedNoteHistory.date" id="parsedNoteHistory.date.id{{caseNoteId}}{{i}}"></span>
        </div>
      </div>
      <div class="eap-two-columns">
        <div class="eap-two-column" style="padding: 1.1rem 0 1.1rem 0;" >
            <span [innerHTML]="parsedNoteHistory.content" id="parsedNoteHistory.content.id{{caseNoteId}}{{i}}"></span>
        </div>
      </div>
    </div>
  </div>
</section>
