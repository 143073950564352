export interface MccssErrorResponse {
  headers: any;
  /**
   * system status - 404, 401, etc
   */
  status: number;
  /**
   * system status text
   */
  statusText: string;
  /**
   * requested url
   */
  url: string;
  /**
   * system flag
   */
  ok: boolean;
  /**
   * Name description; ex: HttpErrorResponse
   */
  name: string;
  /**
   * system message
   */
  message: 'Http failure response for https://est.intra.ws.dev.bil.css.gov.on.ca/api/eap/plan-home/abc: 404 Not Found';
  /**
   * Actual API response object that supposed to have data in it.
   * Instead, it will only have errorResponse[] with custom objects of type { code: string, message: string}
   */
  error: any;
}

/**
 * UNUSED: check and remove. custom error object generated by APIs
 */
export interface MccssCodeMsg {
  code: string;
  message: string;
}

export const BILErrorCodes= {
  BIL0001: 'BIL0001', //Bad Request from request system
  BIL1001: 'BIL1001', //Unknown
  BIL1002: 'BIL1002', //Backend System Connection Issue
  BIL1003: 'BIL1003', //Backend System Issue
  BIL1004: 'BIL1004'  //Record Not Found
}
