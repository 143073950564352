<p class="eap-data-row">
  <!-- render start: data label -->
  <span [class]="'label' + ' row-item ' + 'p'+conf.fxFlex.label" id="{{ this.labelId }}">{{
    this.label
  }}</span>
  <!-- render end: data label -->

  <!-- render start: if data is not entered -->
  <span *ngIf="!this.data" [class]="'row-item ' + 'p' + conf.fxFlex.data">
    {{ 'generic.notApplicable' | translate }}
  </span>
  <!-- render end: if data is not entered -->

  <!-- render start: if data is entered and no screen reader data passed -->
  <span
    *ngIf="this.data !== null && !this.dataForScreenReader"
    [class]="'row-item ' + 'p' + conf.fxFlex.data"
  >
    <span>{{ this.data }}</span>
    <mccss-history-link [history]="this.history"></mccss-history-link>
  </span>

  <!-- render end: if data is entered and no screen reader data passed -->

  <!-- render start: if data is entered and screen reader data passed -->
  <span *ngIf="dataForScreenReader" [class]="'row-item ' + 'p' + conf.fxFlex.data">
    <span aria-hidden="true">{{ this.data }}</span>
    <span class="sr-only" aria-hidden="false">{{
      this.dataForScreenReader
    }}</span>
    <mccss-history-link [history]="this.history"></mccss-history-link>
  </span>
  <!-- render end: if data is entered and screen reader data passed -->
</p>
