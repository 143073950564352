import { Injectable } from '@angular/core';
import {BehaviorSubject, filter, Observable, of, repeat, timeout} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private urls = new BehaviorSubject<Array<string>>([]);

  private noLoadingActivity = (src: Observable<Array<string>>) => src.pipe(
    filter(urls => urls.length === 0)
  )

  timeoutOn = (src: Observable<Array<string>>, spinnerTimeInMS) => src.pipe(
    this.noLoadingActivity,
    map(() => false),
    timeout({each: spinnerTimeInMS}),
    catchError(()=> of(true)),
    repeat()
  )

  public isLoading: Observable<boolean> = this.timeoutOn(this.urls.asObservable(), this.env?.spinnerTimeInMS || 1000);
  public isLongLoading: Observable<boolean> = this.timeoutOn(this.urls.asObservable(), 8000); // time is defined in https://designsystem.ontario.ca/components/detail/loading-indicator.html

  constructor(private env: any) { }

  setLoading(url: string){
    this.urls.next([... this.urls.value, url]);
  }

  removeLoading(url: string) {
    this.urls.next([... this.urls.value.filter(u => u !== url)]);
  }

  emptyLoading() {
    this.urls.next([]);
  }

}
