import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgModule,
  APP_INITIALIZER,
  APP_BOOTSTRAP_LISTENER,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from '@angular/common/http';

import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

// screen modules
import { HomeModule } from './components/container/home/home.module';
import { PlanSummaryListModule } from './components/feature/plan-summary/plan-summary-list/plan-summary-list.module';

import {
  AzureInsightService,
  ApplicationInsightsServiceLoader,
} from '@mccss/shared/monitor';

// services:
import {
  AppInputServiceService,
  AppInputServiceLoader,
} from './services/app-input-service/app-input-service.service';

import { ErrorIntercept } from './services/core/request-response.interceptor';
import { EapHeaderModule } from './components/feature/eap-header/eap-header.module';
import { GlobalErrorHandler } from './services/core/global-error-handler.service';
import { TabNavigationModule } from './components/feature/tab-navigation/tab-navigation.module';
import { DialogMsgService } from './components/feature/dialog-msg/dialog-msg.service';
import { StringHelperService } from './services/shared/StringHelper.service';
import { HistoryDialogModule } from './components/feature/history-dialog/history-dialog.module';
import { HistoryDialogService } from './components/feature/history-dialog/history-dialog.service';
import {AppRoutingModule} from './app-routing.module';
import {DialogMsgModule} from "./components/feature/dialog-msg/dialog-msg.module";
import {LoadingService} from "./services/core/loading.service";
import {HttpRequestLoadingInterceptor} from "./services/interceptors/http-request-loading-interceptor.service";
import { SamsUserService, SamsUserServiceLoader } from "./services/sams-user/sams-user.service";

/**
 * Allows translation service to work with HttpInterceptor
 */
export function translateHttpLoaderFactory(
  httpBackend: HttpBackend
): TranslateHttpLoader {
  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    './assets/i18n/',
    '.json'
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    /**
     * Allows translation service to work with HttpInterceptor
     */
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    TabNavigationModule,
    HomeModule,
    EapHeaderModule,
    PlanSummaryListModule,
    HistoryDialogModule,
    DialogMsgModule,

  ],
  exports: [TranslateModule],
  providers: [
    { provide: environment, useValue: environment },
    DialogMsgService,
    HistoryDialogService,
    StringHelperService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      deps: [DialogMsgService, TranslateService],
      multi: true,
    },
    AzureInsightService,
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInsightsServiceLoader,
      deps: [AzureInsightService, environment, Injector],
      multi: true,
    },
    AppInputServiceService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInputServiceLoader,
      deps: [AppInputServiceService, environment],
      multi: true,
    },
    SamsUserService,
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: SamsUserServiceLoader,
      deps: [SamsUserService, environment],
      multi: true,
    },
    {
      provide: LoadingService,
      useFactory: (env) => new LoadingService(env),
      deps: [environment]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestLoadingInterceptor,
      deps: [LoadingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
