import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { filter, Observable, switchMap } from "rxjs";
import { first, tap } from 'rxjs/operators';

import { AppInputServiceService } from '../app-input-service/app-input-service.service';
import { HistoryChangeService } from './history-change.service';

import { HistoryChangeRequest } from './HistoryChangeRequest';
import { HistoryDataType } from '../history/HistoryDataType';

@Injectable()
export class HistoryChangeResolver implements Resolve<Observable<any>> {
  /**
   * indicates if resolve() was already executed
   */
  private planOutcomeWasInvoked = false;
  /**
   * indicates if resolve() was already executed
   */
  private planContentWasInvoked = false;
  /**
   * indicates if resolve() was already executed
   */
  private planHomeWasInvoked = false;
  /**
   * indicates if resolve() was already executed
   */
  private planSummaryWasInvoked = false;

  constructor(
    private hcs: HistoryChangeService,
    private appInputService: AppInputServiceService
  ) {
  }

  /**
   * IMPORTANT: Can't cancel navigation. bug in Angular https://github.com/angular/angular/issues/21961  !!!!!!!!!!!!!!!!
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (this.isServiceTurnedOff()) {
      return null; // stop further execution
    }

    const request = this.buildRequest(route);
    if (this.wasInvoked(request)) {
      // if the resolver was already executed for this history Change API
      return null; // stop further execution
    }

    // tell the servides to use mock response if needed for development
    this.hcs.useMockResponse = this.appInputService.useMockResponse;
    return this.appInputService.caseReferenceNumber$.pipe(
      filter(id => !!id),
      switchMap(id => this.hcs.getData(request, id)),
      first(),
      tap(() => {
        this.setInvoked(request); // stop resolver from executing again when user navigates to the same route again
      })
    );
  }

  /**
   * Check if the service is turned of by configuration in the environments.ts file
   * @returns true if service is turned off
   */
  private isServiceTurnedOff(): boolean {
    if (this.hcs.conf.isTurnedOff) {
      return true;
    }
    return false;
  }

  /**
   * Check if specific API was called by checking HistoryChangeRequest.dataType property
   * which maps to @see {HistoryDataType}
   * @param r @see {HistoryChangeRequest} - history request object
   * @returns {boolean} - indicates if specific API was already called
   */
  private wasInvoked(r: HistoryChangeRequest): boolean | Observable<never> {
    if (
      r.dataType === HistoryDataType.PlanOutcomeApi &&
      this.planOutcomeWasInvoked
    ) {
      return this.planOutcomeWasInvoked;
    } else if (
      r.dataType === HistoryDataType.PlanSummaryApi &&
      this.planSummaryWasInvoked
    ) {
      return this.planSummaryWasInvoked;
    } else if (
      r.dataType === HistoryDataType.PlanHomeApi &&
      this.planHomeWasInvoked
    ) {
      return this.planHomeWasInvoked;
    } else if (
      r.dataType === HistoryDataType.PlanContentApi &&
      this.planContentWasInvoked
    ) {
      return this.planContentWasInvoked;
    }
    return false; // indicate that service was not called
  }

  /**
   * Set flag to TRUE in order to indicate that specific History Change API was called
   * based on HistoryChangeRequest.dataType property which maps to @see {HistoryDataType}
   * @param r @see {HistoryChangeRequest} - history request object
   */
  private setInvoked(r: HistoryChangeRequest): void {
    if (r.dataType === HistoryDataType.PlanOutcomeApi) {
      this.planOutcomeWasInvoked = true;
    } else if (r.dataType === HistoryDataType.PlanSummaryApi) {
      this.planSummaryWasInvoked = true;
    } else if (r.dataType === HistoryDataType.PlanHomeApi) {
      this.planHomeWasInvoked = true;
    } else if (r.dataType === HistoryDataType.PlanContentApi) {
      this.planContentWasInvoked = true;
    }
  }

  private buildRequest(route: ActivatedRouteSnapshot): HistoryChangeRequest {
    //check if route has HistoryChangeService config object
    let r: HistoryChangeRequest = null;
    if (
      route.data &&
      route.data.historyChangeServiceConf &&
      route.data.historyChangeServiceConf.dataType
    ) {
      r = {
        dataType: route.data.historyChangeServiceConf.dataType
      } as HistoryChangeRequest;
    } else {
      //   throw new Error(
      //     'Route data does not have configuration for HistoryChangeRequest object.'
      //   );
    }
    return r;
  }
}
