/**
 * Identifies a PlanContentResponse.eapPlanContentDetails list type
 * to be used for processing
 */
export enum PlanContentDetailListType {
  /**
   * Identifies(represents) PlanContentResponse.eapPlanContentDetails.planContentForLifeStabilization
   * list to be used/selected for computations
   */
  LifeStabilization = 'LIFE_STABILIZATION',
  /**
   * Identifies(represents) PlanContentResponse.eapPlanContentDetails.planContentForEmployment
   * list to be used/selected for computations
   */
  Employment = 'EMPLOYMENT',
  /**
   * Identifies(represents) PlanContentResponse.eapPlanContentDetails.planContentForRetention
   * list to be used/selected for computations
   */
  Retention = 'RETENTION',
  /**
   * Identifies(represents) PlanContentResponse.eapPlanContentDetails.planContentForSpecializedServices
   * list to be used/selected for computations
   */
  SpecializedServices = 'SPECIALIZED_SERVICES',
}
