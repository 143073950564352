/**
 * Identifies API (url part) of the property for which
 * the history records must be pulled using
 * the History Service. Enum will be used to construct
 * URL for the History Service
 */
export enum HistoryDataType {
  PlanHomeApi = 'home',
  PlanContentApi = 'content',
  PlanSummaryApi = 'summary',
  PlanOutcomeApi = 'outcome',
  EoCaseOwnerApi = 'CaseOwner',
}
