<mccss-skip-to-main
  [linkText]="'skipToMain.linkText' | translate"
  [selector]="'h1'"
></mccss-skip-to-main>
<mccss-logged-in-user-icon data-e2e="user-login-icon" class="sticky"></mccss-logged-in-user-icon>

<section class="container eap-header" role="banner">
  <div
    *ngIf="(phs.planHomeResponse$ | async).eapHome as phr"
    class="form-control-container"
  >
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.concernRoleName' | translate"
        [data]="phr.concernRoleName"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.samsMemberID' | translate"
        [data]="ais.samsMemberId"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>

    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.programName' | translate"
        [data]="'Employment Action Plan'"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.providerName' | translate"
        [data]="phr.providerName"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>

    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.sourceOfData' | translate"
        [data]="'CaMS System, MLTSD'"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [dataForScreenReader]="phr.registrationDateScreenReader"
        [label]="'eapHeader.registrationDate' | translate"
        [data]="phr.registrationDate"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.registrationDate',
          caseRef: phr.caseReference,
          headerKey: 'eapHeader.registrationDate' | translate
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>

    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.caseReference' | translate"
        [data]="phr.caseReference"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [dataForScreenReader]="phr.closedDateScreenReader"
        [label]="'eapHeader.closedDate' | translate"
        [data]="phr.closedDate"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.closedDate',
          caseRef: phr.caseReference,
          headerKey: 'eapHeader.closedDate' | translate
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>

    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [conf]="{ fxFlex: { label: 35, data: 65 } }"
        [label]="'eapHeader.statusCodeDescription' | translate"
        [data]="phr.statusCodeDescription"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.statusCode',
          caseRef: phr.caseReference,
          headerKey: 'eapHeader.statusCodeDescription' | translate
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
  </div>
</section>
