/**
 * Identifies property names for different API response classes.
 * These property names are used to construct HistoryRequest object
 * for HistoryService to dynamically generate API URLs
 * in order to make a History API request
 */
export enum PropName {
  /**
   * Plan Summary Response. This is the property
   * under root of the object
   */
  eapPlanSummary = 'eapPlanSummary',
  /**
   * Plan Outcome Response. This is the property
   * under root of the object
   */
  eapOutcomeList = 'eapOutcomeList',
  /**
   * Plan Home Response. This is the property
   * under root of the object
   */
  eapHome = 'eapHome',
  /**
   * PLAN CONTENT RESPONSE This is child property of
   * 'eapPlanContentDetails'.
   */
  Employment = 'planContentForEmployment',
  /**
   * PLAN CONTENT RESPONSE
   * This is child property of
   * 'eapPlanContentDetails'.
   */
  LifeStab = 'planContentForLifeStabilization',
  /**
   * PLAN CONTENT RESPONSE
   * This is child property of
   * 'eapPlanContentDetails'.
   */
  Retention = 'planContentForRetention',
  /**
   * PLAN CONTENT RESPONSE
   * This is child property of
   * 'eapPlanContentDetails'.
   */
  SpecSrvs = 'planContentForSpecializedServices',

  /**
   * PLAN CONTENT RESPONSE
   * This is child property of one of these:
   * 1) planContentForEmployment
   * 2) planContentForLifeStabilization
   * 3) planContentForRetention
   * 4) planContentForSpecializedServices
   */
  planItemDetails = 'planItemDetails',
}
