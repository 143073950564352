import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mccss-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
})
export class TabNavigationComponent {
  /**
   * stores active link value; in this case, the value is
   * from ngx Translate service which is assigned to tab's text.
   * This property is set by tab's (click) event and [active] attribute
   */
  activeLink: string = null;

  constructor(public translate: TranslateService) {
    this.activeLink = translate.instant('tabNavigation.home'); // set default active tab link to Home
  }
}
