<section class="container" role="region" aria-labelledby="eoCaseOwnerHeader">
  <mccss-h2-title [id]="'eoCaseOwnerHeader'" [title]="'eoCaseOwner.eoCaseOwnerTitle' | translate"></mccss-h2-title>
  <div
    class="form-control-container"
    *ngIf="cws.caseWorkerResponse$ | async as cwr"
  >
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eoCaseOwner.userFullName' | translate"
        [data]="cwr.userFullName"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eoCaseOwner.phoneNumber' | translate"
        [data]="cwr.phoneNumber"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eoCaseOwner.emailAddress' | translate"
        [data]="cwr.emailAddress"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eoCaseOwner.providerName' | translate"
        [data]="cwr.providerName"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
  </div>
</section>
