<nav mat-tab-nav-bar color="primary" class="mat-tab-nav-bar" role="navigation" [tabPanel]="tabPanel">
  <a
    data-e2e="home-tab"
    mat-tab-link
    class="mat-tab-link"
    [class.mat-tab-label-active]="activeLink === translate.instant('tabNavigation.home')"
    (click)="activeLink = translate.instant('tabNavigation.home')"
    [active]="activeLink === translate.instant('tabNavigation.home')"
    [routerLink]="''"
  >
    {{ 'tabNavigation.home' | translate }}
  </a>
  <a
    data-e2e="plan-content-tab"
    mat-tab-link
    class="mat-tab-link"
    [class.mat-tab-label-active]="activeLink === translate.instant('tabNavigation.planContent')"
    (click)="activeLink = translate.instant('tabNavigation.planContent')"
    [active]="activeLink === translate.instant('tabNavigation.planContent')"
    [routerLink]="'plan-content'"
  >
    {{ 'tabNavigation.planContent' | translate }}
  </a>
  <a
    data-e2e="plan-summary-tab"
    mat-tab-link
    class="mat-tab-link"
    [class.mat-tab-label-active]="activeLink === translate.instant('tabNavigation.planSummary')"
    (click)="activeLink = translate.instant('tabNavigation.planSummary')"
    [active]="activeLink === translate.instant('tabNavigation.planSummary')"
    [routerLink]="'plan-summary'"
  >
    {{ 'tabNavigation.planSummary' | translate }}
  </a>
  <a
    data-e2e="outcome-tab"
    mat-tab-link
    class="mat-tab-link"
    [class.mat-tab-label-active]="activeLink === translate.instant('tabNavigation.outcome')"
    (click)="activeLink = translate.instant('tabNavigation.outcome')"
    [active]="activeLink === translate.instant('tabNavigation.outcome')"
    [routerLink]="'outcome'"
  >
    {{ 'tabNavigation.outcome' | translate }}
  </a>
  <a
    data-e2e="case-note-tab"
    mat-tab-link
    class="mat-tab-link"
    [class.mat-tab-label-active]="activeLink === translate.instant('tabNavigation.caseNote')"
    (click)="activeLink = translate.instant('tabNavigation.caseNote')"
    [active]="activeLink === translate.instant('tabNavigation.caseNote')"
    [routerLink]="'case-note'"
  >
    {{ 'tabNavigation.caseNote' | translate }}
  </a>
  <a
    data-e2e="case-owner-tab"
    mat-tab-link
    class="mat-tab-link"
    [class.mat-tab-label-active]="activeLink === translate.instant('tabNavigation.eoCaseOwner')"
    (click)="activeLink = translate.instant('tabNavigation.eoCaseOwner')"
    [active]="activeLink === translate.instant('tabNavigation.eoCaseOwner')"
    [routerLink]="'eap-caseowner'"
  >
    {{ 'tabNavigation.eoCaseOwner' | translate }}
  </a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
<mat-divider></mat-divider>
