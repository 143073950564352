import { ENVIRONMENT_INITIALIZER, NgModule } from "@angular/core";
import {RouterModule, Routes, TitleStrategy} from '@angular/router';
import {HomeComponent} from './components/container/home/home.component';
import {PlanHomeResolver} from './services/plan-home/plan-home.resolver';
import {HistoryChangeResolver} from './services/history-change/history-change.resolver';
import {HistoryDataType} from './services/history/HistoryDataType';
import {EOCaseOwnerComponent} from './components/container/eocase-owner/eocase-owner.component';
import {CaseworkerResolver} from './services/caseworker/caseworker.resolver';
import {PlanOutcomeResolver} from './services/plan-outcome/plan-outcome.resolver';
import {PlanContentResolver} from './services/plan-content/plan-content.resolver';
import {PlanSummaryResolver} from './services/plan-summary/plan-summary.resolver';
import {PageTitleStrategyService} from "./services/core/page-title-strategy.service";
import { PlanHomeService, PlanHomeServiceLoader } from "./services/plan-home/plan-home.service";
import { environment } from "../environments/environment";
import { PlanSummaryService, PlanSummaryServiceLoader } from "./services/plan-summary/plan-summary.service";
import { PlanOutcomeService, PlanOutcomeServiceLoader } from "./services/plan-outcome/plan-outcome.service";
import { PlanContentService, PlanContentServiceLoader } from "./services/plan-content/plan-content.service";
import { CaseworkerService, CaseworkerServiceLoader } from "./services/caseworker/caseworker.service";
import { HistoryService, HistoryServiceLoader } from "./services/history/history.service";
import { AppInputServiceService } from "./services/app-input-service/app-input-service.service";
import { HistoryChangeService, HistoryChangeServiceLoader } from "./services/history-change/history-change.service";
import {CaseNoteComponent} from "./components/container/case-note/case-note.component";

const hist = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useFactory: HistoryServiceLoader,
    deps: [HistoryService, AppInputServiceService, environment],
    multi: true,
  },
  {
    provide: ENVIRONMENT_INITIALIZER,
    useFactory: HistoryChangeServiceLoader,
    deps: [HistoryChangeService, environment],
    multi: true,
  },
  HistoryChangeResolver,];

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    //loadChildren: () => import('./components/container/home/home.module').then((m) => m.HomeModule),
    component: HomeComponent,
    title: 'container.home.h1',
    providers: [
      ...hist,
      {
        provide: ENVIRONMENT_INITIALIZER,
        useFactory: PlanHomeServiceLoader,
        deps: [PlanHomeService, environment],
        multi: true
      }
    ],
    resolve: {
      planHomeResponse: PlanHomeResolver,
      historyChangeResponse: HistoryChangeResolver,
    },
    data: {
      historyChangeServiceConf: {
        dataType: HistoryDataType.PlanHomeApi, //call HistoryChange API for plan home
      },
    },
  },
  {
    path: 'eap-caseowner',
    loadChildren: () => import('./components/container/eocase-owner/eocase-owner.module').then((m) => m.EOCaseOwnerModule),
    component: EOCaseOwnerComponent,
    title: 'container.eoCaseOwner.h1',
    providers: [
      {
        provide: ENVIRONMENT_INITIALIZER,
        useFactory: CaseworkerServiceLoader,
        deps: [CaseworkerService, environment],
        multi: true,
      }
    ],
    resolve: { caseworkerResponse: CaseworkerResolver },
  },
  {
    path: 'outcome',
    pathMatch: 'full',
    loadChildren: () => import('./components/container/outcome/outcome.module').then((m) => m.OutcomeModule),
    title: 'container.outcome.h1',
    providers: [
      ...hist,
      {
        provide: ENVIRONMENT_INITIALIZER,
        useFactory: PlanOutcomeServiceLoader,
        deps: [PlanOutcomeService, environment],
        multi: true,
      }
    ],
    resolve: {
      planOutcomeResponse: PlanOutcomeResolver,
      historyChangeResponse: HistoryChangeResolver,
    },
    data: {
      historyChangeServiceConf: {
        dataType: HistoryDataType.PlanOutcomeApi, //call HistoryChange API for plan outcome
      },
    },
  },
  {
    path: 'plan-content',
    pathMatch: 'full',
    loadChildren: () => import('./components/container/plan-content/plan-content.module').then((m) => m.PlanContentModule),
    title: 'container.planContent.h1',
    providers: [
      ...hist,
      {
        provide: ENVIRONMENT_INITIALIZER,
        useFactory: PlanContentServiceLoader,
        deps: [PlanContentService, environment],
        multi: true,
      }
    ],
    resolve: {
      planContentResponse: PlanContentResolver,
      historyChangeResponse: HistoryChangeResolver,
    },
    data: {
      historyChangeServiceConf: {
        dataType: HistoryDataType.PlanContentApi, //call HistoryChange API for plan home
      },
    },
  },
  {
    path: 'plan-summary',
    pathMatch: 'full',
    loadChildren: () => import('./components/container/plan-summary/plan-summary.module').then((m)=>m.PlanSummaryModule),
    title: 'container.planSummary.h1',
    providers: [
      ...hist,
      {
        provide: ENVIRONMENT_INITIALIZER,
        useFactory: PlanSummaryServiceLoader,
        deps: [PlanSummaryService, environment],
        multi: true,
      }
    ],
    resolve: {
      planSummaryResponse: PlanSummaryResolver,
      historyChangeResponse: HistoryChangeResolver,
    },
    data: {
      historyChangeServiceConf: {
        dataType: HistoryDataType.PlanSummaryApi, //call HistoryChange API for plan summary
      },
    },
  },
  {
    path: 'case-note',
    loadChildren: () => import('./components/container/case-note/case-note.module').then((m) => m.CaseNoteModule),
    component: CaseNoteComponent,
    title: 'container.caseNotes.h1'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {provide: TitleStrategy, useClass: PageTitleStrategyService}
  ]
})
export class AppRoutingModule { }
