import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, filter, Observable, of, switchMap, throwError } from "rxjs";
import { map, first, share, catchError } from 'rxjs/operators';
import { HistoryChangeRequest } from "./HistoryChangeRequest";
import { HistoryDataType } from '../history/HistoryDataType';
import { BaseAPIService } from '../shared/base-api/base-api.service';
import { HistoryResponse } from "../history/HistoryResponse";

/**
 * HistoryChangeServiceLoader to be used by importing module (ex: app module).
 * @param s HistoryChangeService injection
 * @param environments environments/environment.ts
 */
export function HistoryChangeServiceLoader(
  s: HistoryChangeService,
  environments: any
) {
  // obtain environment.ts configurations
  return () => s.setEnvApiConf(environments.api.historyChangeService);
}

@Injectable({
  providedIn: 'root',
})
export class HistoryChangeService extends BaseAPIService {
  /**
   * Holds History Change Response object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planOutcome = new BehaviorSubject<any>(null);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planOutcome$: Observable<any> = this._planOutcome.asObservable();
  /**
   * Holds History Change Response object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planSummary = new BehaviorSubject<{ eapPlanSummary: any }>(null);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planSummary$: Observable<{ eapPlanSummary: any }> = this._planSummary.asObservable();
  /**
   * Holds History Change Response object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planContent = new BehaviorSubject<{ eapPlanContentDetails: any }>(null);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planContent$: Observable<{ eapPlanContentDetails: any }> = this._planContent.asObservable();
  /**
   * Holds History Change Response object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planHome = new BehaviorSubject<{ eapHome: any } >(null);

  public allFieldsHistoryChanges$ = new BehaviorSubject<{[fieldPath: string]: HistoryResponse }>({});

  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planHome$: Observable<{ eapHome: any }> = this._planHome.asObservable();
  /**
   * Holds History Change Response object for internal processing ONLY.
   * This property MUST be private.
   */
  private _eoCaseowner = new BehaviorSubject<any>(null);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public eoCaseowner$: Observable<any> = this._eoCaseowner.asObservable();

  constructor(public http: HttpClient) {
    super();
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(r: HistoryChangeRequest): string {
    return this.useMockResponse
      ? this.computeMockURL(r)
      : this.conf.path + '/' + r.dataType + '/casereference/' + r.caseRef;

  }

  private computeMockURL(r: HistoryChangeRequest): string {
    let url: string = null;
    if (r.dataType === HistoryDataType.PlanOutcomeApi) {
      url = this.conf.additionalData['mockUrlPlanOutcome'];
    } else if (r.dataType === HistoryDataType.PlanSummaryApi) {
      url = this.conf.additionalData['mockUrlPlanSummary'];
    } else if (r.dataType === HistoryDataType.PlanHomeApi) {
      url = this.conf.additionalData['mockUrlPlanHome'];
    } else if (r.dataType === HistoryDataType.PlanContentApi) {
      url = this.conf.additionalData['mockUrlPlanContent'];
    } else if (r.dataType === HistoryDataType.EoCaseOwnerApi) {
      console.error(
        'there is no mock url for HistoryChangeService. Add functionality'
      );
    } else {
      throw new Error(this.conf.appError['noDataType']);
    }
    return url;
  }

  /**
   * Load data from back end
   */
  getData(r: HistoryChangeRequest, caseRef: string): Observable<any> {

    return this.http
      .get<any>(this.computeApiURL({ ...r, caseRef }), {
        headers: this.getHttpOptions(),
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          this.massageData(r.dataType, data);
          return data;
        }),
        // we need to catch error in order to suppress the 404 error
        catchError((error) => {
          if (error.status === 404) {
            return of({});
          } else {
            return throwError(error);
          }
        })
      )

  }

  private massageData(dataType: HistoryDataType, response: { [prop: string]: any, fieldChanges: {[fieldPath: string]: HistoryResponse } }): void {
    this.allFieldsHistoryChanges$.next({...this.allFieldsHistoryChanges$.value, ...response.fieldChanges});
    if (dataType === HistoryDataType.PlanOutcomeApi) {
      this._planOutcome.next({ eapOutcomeList: response.eapOutcomeList });
    } else if (dataType === HistoryDataType.PlanSummaryApi) {
      this._planSummary.next({ eapPlanSummary: response.eapPlanSummary });
    } else if (dataType === HistoryDataType.PlanHomeApi) {
      this._planHome.next({eapHome: response.eapHome});
    } else if (dataType === HistoryDataType.PlanContentApi) {
      this._planContent.next({ eapPlanContentDetails: response.eapPlanContentDetails });
    } else if (dataType === HistoryDataType.EoCaseOwnerApi) {
      this._eoCaseowner.next(response);
    } else {
      throw new Error(this.conf.appError['ngxNoDataType']);
    }
  }
}
