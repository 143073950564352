import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoggedInUserIconComponent } from './logged-in-user-icon.component';

@NgModule({
  declarations: [LoggedInUserIconComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true,
    }),
  ],
  exports: [LoggedInUserIconComponent],
})
export class LoggedInUserIconModule {}
