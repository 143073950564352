import { Component } from '@angular/core';

@Component({
  selector: 'mccss-mat-row-two-columns,[mccss-mat-row-two-columns]',
  templateUrl: './mat-row-two-columns.component.html',
  styleUrls: ['./mat-row-two-columns.component.scss'],
})
export class MatRowTwoColumnsComponent {
  constructor() {}
}
