import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { EapRowComponent } from './eap-row.component';
import { HistoryLinkModule } from '../history-link/history-link.module';

@NgModule({
  declarations: [EapRowComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true,
    }),
    HistoryLinkModule,
  ],
  exports: [EapRowComponent],
})
export class EapRowModule {}
