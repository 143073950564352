import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first, share } from 'rxjs/operators';

import { PlanContentResponse } from './PlanContentResponse';
import { EAPPlanContentDetails } from './EAPPlanContentDetails';
import { EAPSubGoalDetails } from './EAPSubGoalDetails';
import { CamsAPIResponse } from '../shared/CamsAPIResponse';

import { PlanContentDetailListType } from './PlanContentDetailListType';
import { EAPPlanItemDetails } from './EAPPlanItemDetails';
import { TranslateService } from '@ngx-translate/core';
import { StringHelperService } from '../shared/StringHelper.service';
import { BaseAPIService } from '../shared/base-api/base-api.service';
import { AppInputServiceService } from '../app-input-service/app-input-service.service';

/**
 * PlanSummaryServiceLoader to be used by importing module (ex: app module).
 * @param s PlanSummaryService injection
 * @param environments environments/environment.ts
 */
export function PlanContentServiceLoader(
  s: PlanContentService,
  environments: any
) {
  return () => s.setEnvApiConf(environments.api.planContent);
}

@Injectable({
  providedIn: 'root',
})
export class PlanContentService extends BaseAPIService {
  /**
   * expose PlanContentDetailListType enum to the template
   */
  planContentDetailListType = PlanContentDetailListType;
  /**
   * Holds PlanContentResponse object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planContentResponse = new BehaviorSubject<PlanContentResponse>(
    PlanContentService.init()
  );
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planContentResponse$: Observable<PlanContentResponse> =
    this._planContentResponse.asObservable();

  public expandedPlanContentForLifeStabilization: EAPSubGoalDetails[] = [];
  public expandedPlanContentForEmployment: EAPSubGoalDetails[] = [];
  public expandedPlanContentForRetention: EAPSubGoalDetails[] = [];
  public expandedPlanContentForSpecializedServices: EAPSubGoalDetails[] = [];

  /**
   * Aria text for close button located on the plan content page.
   */
  public closeBtnAria: string;
  /**
   * Aria text for open button located on the plan content page.
   */
  public openBtnAria: string;

  constructor(
    public http: HttpClient,
    private translate: TranslateService,
    private strHelper: StringHelperService,
    private appInputService: AppInputServiceService
  ) {
    super();
    this.subscribeToButtonTranslation();
  }

  /**
   * Returns empty PlanContentResponse object
   */
  public static init(): PlanContentResponse {
    const ps: PlanContentResponse = {
      eapPlanContentDetails: {
        planContentForLifeStabilization: [] as EAPSubGoalDetails[],
        planContentForEmployment: [] as EAPSubGoalDetails[],
        planContentForRetention: [] as EAPSubGoalDetails[],
        planContentForSpecializedServices: [] as EAPSubGoalDetails[],
        camsAPIResponse: {} as CamsAPIResponse,
      } as EAPPlanContentDetails,
      errorResponse: null,
      descriptionMap: {},
    } as PlanContentResponse;
    return ps;
  }

  /**
   * Returns a reference to correct list property of this service.
   * Reference can be one of the following properties:
   * 1) planContentForLifeStabilization
   * 2) planContentForEmployment
   * 3) planContentForRetention
   * 4) planContentForSpecializedServices
   * @param listType PlanContentDetailListType enum - identifies the list that
   * we need to operate on
   */
  public listSelector(listType: string): EAPSubGoalDetails[] {
    let list: EAPSubGoalDetails[] = null; // returns correct list to operate on
    switch (listType) {
      case PlanContentDetailListType.LifeStabilization: {
        list =
          this.getLastPlanContentResponseValue().eapPlanContentDetails
            .planContentForLifeStabilization;
        break;
      }
      case PlanContentDetailListType.Employment: {
        list =
          this.getLastPlanContentResponseValue().eapPlanContentDetails
            .planContentForEmployment;
        break;
      }
      case PlanContentDetailListType.Retention: {
        list =
          this.getLastPlanContentResponseValue().eapPlanContentDetails
            .planContentForRetention;
        break;
      }
      case PlanContentDetailListType.SpecializedServices: {
        list =
          this.getLastPlanContentResponseValue().eapPlanContentDetails
            .planContentForSpecializedServices;
        break;
      }
      default: {
        throw new Error(
          'PlanContentResponse.eapPlanContentDetails list could not be identified'
        );
      }
    }
    return list;
  }

  /**
   * Returns a reference to correct expanded list property of this service.
   * Reference can be one of the following properties:
   * 1) expandedPlanContentForLifeStabilization
   * 2) expandedPlanContentForEmployment
   * 3) expandedPlanContentForRetention
   * 4) expandedPlanContentForSpecializedServices
   * @param listType PlanContentDetailListType enum - identifies the list that
   * we need to operate on
   */
  public expandedListSelector(
    listType: PlanContentDetailListType
  ): EAPSubGoalDetails[] {
    let list: EAPSubGoalDetails[] = null; // returns correct list to operate on
    switch (listType) {
      case PlanContentDetailListType.LifeStabilization: {
        list = this.expandedPlanContentForLifeStabilization;
        break;
      }
      case PlanContentDetailListType.Employment: {
        list = this.expandedPlanContentForEmployment;
        break;
      }
      case PlanContentDetailListType.Retention: {
        list = this.expandedPlanContentForRetention;
        break;
      }
      case PlanContentDetailListType.SpecializedServices: {
        list = this.expandedPlanContentForSpecializedServices;
        break;
      }
      default: {
        throw new Error(
          'PlanContentResponse.expanded* list could not be identified'
        );
      }
    }
    return list;
  }

  /**
   * Checks if element exists in the expanded list based on the listType parameter.
   * Used by the Material Table expanded row.
   * Returns true if the list exists in the expanded list; false otherwise.
   * @param element object of type EAPSubGoalDetails
   * @param listType PlanContentDetailListType enum - identifies the list that
   * we need to operate on
   */
  checkExpanded(
    element: EAPSubGoalDetails,
    listType: PlanContentDetailListType
  ): boolean {
    let flag = false;
    const list = this.expandedListSelector(listType);

    list.forEach((e) => {
      if (e === element) {
        flag = true;
        //TODO: chack if i can break loop when 1st element has a match
      }
    });
    return flag;
  }

  /**
   * Adds or removes element to/from expanded list which is determined
   * by the listType property.
   * Used by the Material Table expanded row.
   * @param element object of type EAPSubGoalDetails
   * @param listType PlanContentDetailListType enum - identifies the list that
   * we need to operate on
   */
  pushPopElement(
    element: EAPSubGoalDetails,
    listType: PlanContentDetailListType
  ): EAPSubGoalDetails {
    const list = this.expandedListSelector(listType);
    const index = list.indexOf(element);
    if (index === -1) {
      list.push(element);
      return element;
    } else {
      list.splice(index, 1);
      return null;
    }
  }

  public getLastPlanContentResponseValue(): PlanContentResponse {
    return this._planContentResponse.value;
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(caseReference: number): string {
    const url = this.useMockResponse
      ? this.conf.mockURL
      : this.conf.path + '/' + caseReference.toString();
    return url;
  }

  /**
   * Load data from back end
   * @param apiPath - path to GET method URL
   */
  getData(caseReference: number): Observable<PlanContentResponse> {
    const resp = this.http
      .get<PlanContentResponse>(this.computeApiURL(caseReference), {
        headers: this.getHttpOptions(),
        withCredentials: true,
      })
      .pipe(
        share(),
        first(), // obtain only 1st emitted data
        map((data) => {
          this.massageData(data, caseReference);
          this._planContentResponse.next(data);
          return data;
        })
      );
    resp.subscribe();
    return resp;
  }

  /**
   * Massages data for each item in the eapPlanSummary[] property by using
   * keys from each EAPPlanSummary item and looking up their description in the
   * PlanSummaryResponse.descriptionMap property
   * @param psr PlanSummaryResponse object
   */
  private massageData(pcr: PlanContentResponse, caseReference: number): void {
    if (this.responseHasData(pcr)) {
      pcr.caseReference = caseReference;
      this.massagemassagePlanContent(
        pcr.eapPlanContentDetails.planContentForLifeStabilization,
        pcr
      );
      this.massagemassagePlanContent(
        pcr.eapPlanContentDetails.planContentForEmployment,
        pcr
      );
      this.massagemassagePlanContent(
        pcr.eapPlanContentDetails.planContentForRetention,
        pcr
      );
      this.massagemassagePlanContent(
        pcr.eapPlanContentDetails.planContentForSpecializedServices,
        pcr
      );
      // Handling planContentForEmployment specifically if needed
      pcr.eapPlanContentDetails.planContentForEmployment?.forEach((item) => {
        if (item.name === 'ON161') {
          item.planItemDetails?.forEach(planItem => {
            if (['AN1084', 'AN1085'].includes(planItem.planItemName)) {
              planItem.eapHSTHidden = true;
            }
          });
        }
      });
    } else {
      throw new Error(this.conf.appError['apiResponseMissingData']);
    }
  }

  /**
   * Checks if there is a response and resposne has required properties
   * @param pcr @see {PlanContentResponse} API response
   * @returns true if response has data; false otherwise
   */
  private responseHasData(pcr: PlanContentResponse): boolean {
    return pcr && pcr.eapPlanContentDetails && pcr.descriptionMap
      ? true
      : false;
  }

  private massagemassagePlanContent(
    list: EAPSubGoalDetails[],
    pcr: PlanContentResponse
  ): void {
    if (list) {
      list.forEach((v) => {
        this.massageEapSubGoalDetails(v, pcr);

        if (v.planItemDetails) {
          v.planItemDetails.forEach((pid) => {
            this.massageEAPPlanItemDetails(pid, pcr, v.plannedSubGoalID);
          });
        }
      });
    }
  }

  private massageEapSubGoalDetails(
    o: EAPSubGoalDetails,
    pcr: PlanContentResponse
  ): void {
    o.caseReference = pcr.caseReference.toString();
    o.nameDescription = pcr.descriptionMap[o.name];
    o.statusDescription = pcr.descriptionMap[o.status];
    o.outcomeDescription = pcr.descriptionMap[o.outcome];
    o.startDate = this.strHelper.getDateToNAifNull(o.startDate);
    o.startDateScreenReader = this.strHelper.getScreenReaderDate(o.startDate);
    o.endDate = this.strHelper.getDateToNAifNull(o.endDate);
    o.endDateScreenReader = this.strHelper.getScreenReaderDate(o.endDate);
  }

  private massageEAPPlanItemDetails(
    o: EAPPlanItemDetails,
    pcr: PlanContentResponse,
    plannedSubGoalID: string
  ): void {
    o.plannedSubGoalID = plannedSubGoalID;
    o.caseReference = pcr.caseReference.toString();

    o.planItemNameDescription = pcr.descriptionMap[o.planItemName];
    o.statusDescription = pcr.descriptionMap[o.status];
    o.outcomeAchievedDescription = pcr.descriptionMap[o.outcomeAchieved];

    o.readyForReviewINDDescription =
      pcr.descriptionMap[o.readyForReviewIND] === '1' ? 'Yes' : 'No';

    o.resubmitForReviewINDDescription =
      pcr.descriptionMap[o.resubmitForReviewIND] === '1' ? 'Yes' : 'No';
    // TODO: if statement is probablu unnecessary, undefined is handled by map and components
    if (o.plmtNaicsCode) {
      o.plmtNaicsCodeDescription = pcr.descriptionMap[o.plmtNaicsCode];
    } else {
      o.plmtNaicsCodeDescription = null;
    }
    // TODO: if statement is probablu unnecessary, undefined is handled by map and components
    if (o.plmtEmploymentNOC) {
      o.plmtEmploymentNOCDescription = pcr.descriptionMap[o.plmtEmploymentNOC];
    } else {
      o.plmtEmploymentNOCDescription = null;
    }

    o.actualStartDate = this.strHelper.getDateToNAifNull(o.actualStartDate);
    o.actualStartDateScreenReader = this.strHelper.getScreenReaderDate(
      o.actualStartDate
    );

    o.actualEndDate = this.strHelper.getDateToNAifNull(o.actualEndDate);
    o.actualEndDateScreenReader = this.strHelper.getScreenReaderDate(
      o.actualEndDate
    );

    o.paidDate = this.strHelper.getDateToNAifNull(o.paidDate);
    o.paidDateScreenReader = this.strHelper.getScreenReaderDate(o.paidDate);

    o.employerSatisfaction = pcr.descriptionMap[o.employerSatisfaction] || o.employerSatisfaction;
    o.plmtPlacementCategory = pcr.descriptionMap[o.plmtPlacementCategory] || o.plmtPlacementCategory;
  }

  /**
   * generate random number
   * @param min
   * @param max
   */
  public getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    // Generate a secure random number between 0 and 1
    const random = crypto.getRandomValues(new Uint32Array(1))[0] / (2 ** 32 - 1);
    return Math.floor(random * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  private subscribeToButtonTranslation(): void {
    this.translate
      .get('planContentList.columns.iconAriaLabel.open')
      .subscribe((txt: string) => {
        this.openBtnAria = txt;
      });
    this.translate
      .get('planContentList.columns.iconAriaLabel.close')
      .subscribe((txt: string) => {
        this.closeBtnAria = txt;
      });
  }
}
