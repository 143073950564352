<section
  class="container"
  role="region"
  aria-labelledby="planSummaryListHeading"
>
  <div class="form-control-container">
    <div
      class="patch-ff-width"
    >
      <div>
        <table
          mat-table
          class="mat-elevation-z8"
          role="grid"
          [dataSource]="(psr | async).eapPlanSummary"
        >
          <caption id="planSummaryListHeading">
            {{
              'planSummaryList.title' | translate
            }}
          </caption>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'planSummaryList.action.label' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a (click)="rowClick(element, $event)"
                (keydown)="rowClick(element, $event)"
                role="link" tabindex="0">
                {{
                  (selectedRowKey !== element.servicePlanContractID
                    ? 'planSummaryList.action.select'
                    : 'planSummaryList.action.unselect') | translate
                }}
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'planSummaryList.clientName.label' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ element.clientName }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="dateIssued">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'planSummaryList.dateIssued.label' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mccss-date-span
                [date]="element.dateIssued"
                [date4SR]="element['dateIssuedScreenReader']"
              ></mccss-date-span>
            </td>
          </ng-container>
          <ng-container matColumnDef="statusDescription">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'planSummaryList.statusDescription.label' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ element.statusDescription }}
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              highlight: selectedRowKey === row.servicePlanContractID
            }"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</section>
