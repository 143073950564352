import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { PlanHomeResponse } from "./PlanHomeResponse";
import { EAPHome } from "./EAPHome";
import { StringHelperService } from "../shared/StringHelper.service";
import { BaseAPIService } from "../shared/base-api/base-api.service";

/**
 * PlanSummaryServiceLoader to be used by importing module (ex: app module).
 * @param s PlanSummaryService injection
 * @param environments environments/environment.ts
 */
export function PlanHomeServiceLoader(s: PlanHomeService, environments: any) {
  return () => s.setEnvApiConf(environments.api.planHome);
}

@Injectable({
  providedIn: "root"
})
export class PlanHomeService extends BaseAPIService {
  /**
   * Holds PlanHomeResponse object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planHomeResponse = new BehaviorSubject<PlanHomeResponse>(
    PlanHomeService.init()
  );
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planHomeResponse$: Observable<PlanHomeResponse> =
    this._planHomeResponse.asObservable();

  constructor(public http: HttpClient, private strHelper: StringHelperService) {
    super();
  }

  /**
   * Returns empty PlanHomeResponse object
   */
  public static init(): PlanHomeResponse {
    const ps: PlanHomeResponse = {
      eapHome: {} as EAPHome,
      errorResponse: null,
      descriptionMap: {}
    } as PlanHomeResponse;
    return ps;
  }

  // TODO: check if needed
  public getLastPlanContentResponseValue(): PlanHomeResponse {
    return this._planHomeResponse.value;
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(caseReference: string): string {
    return this.useMockResponse
      ? this.conf.mockURL
      : this.conf.path + "/" + caseReference;
  }

  /**
   * Load data from back end
   */
  getData(refNumber: string) {
    return this.http
      .get<PlanHomeResponse>(this.computeApiURL(refNumber), {
        headers: this.getHttpOptions(),
        withCredentials: true
      })
      .pipe(
        first(), // obtain only 1st emitted data
        map((data) => {
          this.massageData(data);
          this._planHomeResponse.next(data);
        })
      );
  }

  /**
   * Massages data for each item in the eapPlanSummary[] property by using
   * keys from each EAPPlanSummary item and looking up their description in the
   * PlanSummaryResponse.descriptionMap property
   * @param psr PlanSummaryResponse object
   */
  private massageData(pcr: PlanHomeResponse): void {
    if (this.responseHasData(pcr)) {
      this.massagePrimitiveData(pcr);
      this.massageClosedDate(pcr);
      this.massageRegistrationDate(pcr);
    } else {
      throw new Error(this.conf.appError["apiResponseMissingData"]);
    }
  }

  /**
   * Checks if there is a response and resposne has required properties
   * @param pcr @see {PlanHomeResponse} API response
   * @returns true if response has data; false otherwise
   */
  private responseHasData(pcr: PlanHomeResponse): boolean {
    return pcr && pcr.eapHome && pcr.descriptionMap ? true : false;
  }

  /**
   * Maps IDs/Codes to string descriptions.
   * @param pcr @param pcr @see {PlanHomeResponse} API response
   */
  private massagePrimitiveData(pcr: PlanHomeResponse): void {
    pcr.eapHome.referInDescription = pcr.descriptionMap[pcr.eapHome.referIn];
    pcr.eapHome.empGoalNocCDDescription =
      pcr.descriptionMap[pcr.eapHome.empGoalNocCD];
    pcr.eapHome.empOutcomeNocCDDescription =
      pcr.descriptionMap[pcr.eapHome.empOutcomeNocCD];
    pcr.eapHome.goalNameDescription = pcr.descriptionMap[pcr.eapHome.goalName];
    pcr.eapHome.outcomeAchievedDescription =
      pcr.descriptionMap[pcr.eapHome.outcomeAchieved];
    pcr.eapHome.statusCodeDescription =
      pcr.descriptionMap[pcr.eapHome.statusCode];
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param pcr @param pcr @see {PlanHomeResponse} API response
   */
  private massageClosedDate(pcr: PlanHomeResponse): void {
    pcr.eapHome.closedDate = this.strHelper.getDateToNAifNull(
      pcr.eapHome.closedDate
    );
    pcr.eapHome.closedDateScreenReader = this.strHelper.getScreenReaderDate(
      pcr.eapHome.closedDate
    );
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param pcr @param pcr @see {PlanHomeResponse} API response
   */
  private massageRegistrationDate(pcr: PlanHomeResponse): void {
    pcr.eapHome.registrationDate = this.strHelper.getDateToNAifNull(
      pcr.eapHome.registrationDate
    );
    pcr.eapHome.registrationDateScreenReader =
      this.strHelper.getScreenReaderDate(pcr.eapHome.registrationDate);
  }
}
