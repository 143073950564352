export class URLSearchParams {
  public static getParam(paramName: string): number | string {
    const results = new RegExp('[?&]' + paramName + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }
}
