<div *ngIf="loading$ | async" class="loading-container">
  <div class='ontario-loading-indicator__overlay' aria-hidden='false' role='alert' aria-live='assertive'>
    <div class='ontario-loading-indicator'>
      <svg class='ontario-loading-indicator__spinner' viewBox="25 25 50 50" xmlns='http://www.w3.org/2000/svg'>
        <circle cx="50" cy="50" r="30" fill="none" stroke-width="5" />
      </svg>

      <p>{{ (isLongLoading$ | async) ? 'Still Loading' : 'Loading' }}</p>
    </div>
  </div>
</div>

<div class="full-width-gray-bg">
  <mccss-eap-header></mccss-eap-header>
</div>
<div class="container">
  <mccss-tab-navigation></mccss-tab-navigation>
</div>
<div role="main" class="container">
  <router-outlet></router-outlet>
</div>
