import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryLinkComponent } from './history-link.component';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [HistoryLinkComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  exports: [HistoryLinkComponent],
})
export class HistoryLinkModule {}
