import { Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, takeWhile, throttleTime } from "rxjs";
import { DialogMsgComponent } from "./dialog-msg.component";

@Injectable()
export class DialogMsgService {


  public showDialogDataSubject = new Subject<{
    title: string,
    message: string,
    confirmText: string,
  }>();
  private aDialogIsOpened = false;

  constructor(private dialog: MatDialog, private ngZone: NgZone) {

    dialog.afterAllClosed.subscribe(() => {
      this.aDialogIsOpened = false;
    });
    this.showDialogDataSubject.asObservable().pipe(
      throttleTime(500), // only emit ONE on all "immediate(within half second)" sources
      takeWhile(() => !this.aDialogIsOpened)
    ).subscribe(
      {
        next: (data) => {
          this.aDialogIsOpened = true;
          this.open(data);
        }
      }
    );
  }

  public open(options) {
    this.ngZone.run(() => {
      this.dialog.open(DialogMsgComponent, {
        data: {
          title: options.title,
          message: options.message,
          cancelText: options.cancelText,
          confirmText: options.confirmText
        }
      });
    });
  }
}
