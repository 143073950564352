import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {Observable, throwError, NEVER} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogMsgService } from '../../components/feature/dialog-msg/dialog-msg.service';
import { EnvApiConf } from '../shared/base-api/EnvApiConf';
import { environment } from '../../../environments/environment';
import {BILErrorCodes, MccssCodeMsg} from "./MccssErrorResponse";

export class ErrorIntercept implements HttpInterceptor {
  constructor(
    private dialogMsgService: DialogMsgService,
    private translate: TranslateService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),

      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        }

        console.error(errorMessage); // Log the error message

        const apiConfig = this.getApiEnvironmentObject(error);
        if (!this.suppressError(error, apiConfig)) {
          const errorMsgTranslationKey = this.getApiCustomErrorTranslationKey(
            error,
            apiConfig
          );
          this.showErrorMsg2(errorMsgTranslationKey, (error.status===404)?"":"Error");
          return NEVER; //do not emit anything, only show this KNOWN error above.
        }
        return throwError(() => error); // throw UNKNOWN
      })
    );
  }

  private suppressError(e: HttpErrorResponse, apiConfig: EnvApiConf): boolean {
    if (
      apiConfig.appError &&
      apiConfig.appError[e.status.toString()] &&
      apiConfig.appError[e.status.toString()].suppress
    ) {
      return true;
    } else {
      return false;
    }
  }

  private showErrorMsg2(
    errorMsgTranslationKey: string,
    titletext: string
  ) {
    this.translate.get(errorMsgTranslationKey).subscribe((res: string) => {
      const data = {
        title: titletext,
        message: res,
        //cancelText: 'Cancel',
        confirmText: 'Ok',
      };
      this.dialogMsgService.showDialogDataSubject.next(data);
    });
  }

  /**
   * Uses HttpErrorResponse.status in order to select custom error translation key from
   * apiEnvironmentObj object (environment.api.{object}). Ex:
   * 404 - environment.api.{api name}.ngxTranslateKeyError404
   * 500 - environment.api.{api name}.ngxTranslateKeyError500
   * @param e HttpErrorResponse
   * @param apiEnvironmentObj reference to environment.ts configuration object for API (ex: environment.api.{object})
   */
  private getApiCustomErrorTranslationKey(
    e: HttpErrorResponse,
    apiEnvironmentObj: EnvApiConf
  ): string {
    let msg = null;

    if (e.status === 401) {
      msg = apiEnvironmentObj.customError.ngxTranslateKeyError401;
    } else if (e.status === 404) {
      msg = apiEnvironmentObj.customError.ngxTranslateKeyError404;
    } else if (e.status === 500) {
      msg = apiEnvironmentObj.customError.ngxTranslateKeyError500;
    } else if (e.status === 503) {
      const mccessCodeMag = e.error?.[0] as MccssCodeMsg
      if (mccessCodeMag?.code === BILErrorCodes.BIL1002) {
        msg = apiEnvironmentObj.customError.ngxTranslateKeyError503;
      } else {
        msg = apiEnvironmentObj.customError.ngxTranslateKeyError500;
      }
    }
    else if (e.status === 400) {
      msg = apiEnvironmentObj.customError.ngxTranslateKeyError400;
    } else {
      msg = 'generic.unknownError';
    }
    return msg;
  }

  /**
   * Matches HttpErrorResponse.url property to environment's file api.*.path
   * in order to determine which API has failed.
   * Returns the environment.api.{object} that provides configuration for the
   * failed API
   * @param e HttpErrorResponse
   */
  private getApiEnvironmentObject(e: HttpErrorResponse): EnvApiConf {
    let apiConfig: any;

    if (e.url.includes(environment.api.cams2sams.path)) {
      apiConfig = environment.api.cams2sams;
    } else if (e.url.includes(environment.api.planHome.path)) {
      apiConfig = environment.api.planHome;
    } else if (e.url.includes(environment.api.planOutcome.path)) {
      apiConfig = environment.api.planOutcome;
    } else if (e.url.includes(environment.api.planSummary.path)) {
      apiConfig = environment.api.planSummary;
    } else if (e.url.includes(environment.api.planContent.path)) {
      apiConfig = environment.api.planContent;
    } else if (e.url.includes(environment.api.caseworker.path)) {
      apiConfig = environment.api.caseworker;
    } else if (e.url.includes(environment.api.samsUserService.path)) {
      apiConfig = environment.api.samsUserService;
    } else if (e.url.includes(environment.api.historyChangeService.path)) {
      apiConfig = environment.api.historyChangeService;
    } else if (e.url.includes(environment.api.historyService.path)) {
      apiConfig = environment.api.historyService;
    }

    if (apiConfig) {
      // if config found
      return apiConfig;
    } else {
      throw new Error(
        'getApiEnvironmentObject(e: HttpErrorResponse) could not find API object in environment file'
      );
    }
  }
}
