import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatRowTwoColumnsComponent } from './mat-row-two-columns.component';

@NgModule({
  declarations: [MatRowTwoColumnsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [MatRowTwoColumnsComponent],
})
export class MatRowTwoColumnsModule {}
