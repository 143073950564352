import { Component } from '@angular/core';
import { PlanSummaryService } from '../../../../services/plan-summary/plan-summary.service';
import { EAPPlanSummary } from '../../../../services/plan-summary/EAPPlanSummary';

@Component({
  selector: 'mccss-plan-summary-list',
  templateUrl: './plan-summary-list.component.html',
  styleUrls: ['./plan-summary-list.component.scss'],
})
export class PlanSummaryListComponent {
  /**
   * PlanSummaryResponse Observalbe object from PlanSummaryService property
   */
  public psr = this.pss.planSummaryResponse$;
  /**
   * Columns to display in the table. Each array item represents 'matColumnDef' attribute
   * of the ng-container tag
   */
  public displayedColumns: string[] = [
    'action',
    'clientName',
    'dateIssued',
    'statusDescription',
  ];
  /**
   * Set to EAPPlanSummary.servicePlanContractID.
   * Keeps track of which row was selected/unselected.
   * Used for row highlight and action label toggle (select/unselect).
   */
  public selectedRowKey: number = null;

  constructor(public pss: PlanSummaryService) {
    this.initTable();
  }

  /**
   * Checks if user selected plan summary in "current session" and sets UI table's selected row.
   * Used when user navigated to another screen and
   * returned back to Plan Summary screen
   */
  public initTable(): void {
    const ps = this.pss.getSelectedPlanSummary();
    if (ps) {
      // if we have selected plan summary
      this.selectedRowKey = ps.servicePlanContractID; // set selected table row
    }
  }

  /**
   * 'Select' action link click event handler.
   * @param ps -represents selected table row (of type EAPPlanSummary)
   * @param event KeyboardEvent
   */
  public rowClick(ps: EAPPlanSummary, event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.type === 'click') {
      this.selectPlanSummary(ps, event);
      this.highlight(ps);
    }
  }

  /**
   * 'Select' action link click event handler. Sets selected EAPPlanSummary in the
   * PlanSummaryService
   * @param ps - represents selected table row (of type EAPPlanSummary)
   */
  public selectPlanSummary(ps: EAPPlanSummary, event: KeyboardEvent): void {
    if (this.selectedRowKey !== ps.servicePlanContractID) {
      this.pss.selectPlanSummary(ps);
    } else {
      this.pss.unselectPlanSummary();
    }
  }

  /**
   * Highlights the selected table row
   * @param row
   */
  public highlight(row: EAPPlanSummary) {
    if (this.selectedRowKey === row.servicePlanContractID) {
      this.selectedRowKey = null;
    } else {
      this.selectedRowKey = row.servicePlanContractID; // generate 'unique' row key
    }
  }
}
