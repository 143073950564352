import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { EapHeaderComponent } from './eap-header.component';

import { EapRowModule } from '../shared/eap-row/eap-row.module';
import { LoggedInUserIconModule } from '../logged-in-user-icon/logged-in-user-icon.module';
import { SkipToMainModule } from '../skip-to-main/skip-to-main.module';
import { MatRowTwoColumnsModule } from '../shared/mat-row-two-columns/mat-row-two-columns.module';

@NgModule({
  declarations: [EapHeaderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true,
    }),
    EapRowModule,
    LoggedInUserIconModule,
    SkipToMainModule,
    MatRowTwoColumnsModule,
  ],
  exports: [EapHeaderComponent],
  providers: [DatePipe],
})
export class EapHeaderModule {}
