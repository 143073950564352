import {Component, Input} from '@angular/core';
import {ParsedNoteHistory} from "../../../../services/cams2sams/ParsedNoteHistory";

@Component({
  selector: 'mccss-case-note-history',
  templateUrl: './case-note-history.component.html',
  styleUrls: ['./case-note-history.component.scss']
})
export class CaseNoteHistoryComponent {
  @Input() caseNoteId: string;
  @Input() parsedNoteHistories: ParsedNoteHistory[]
}
