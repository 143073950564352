<script src="../case-note-history/case-note-history.component.ts"></script>
<section class="container" role="region" attr.aria-label="{{ 'caseNote.title' | translate }}">

  <div class="form-control-container">
    <mat-accordion togglePosition="before">
      <ng-container *ngFor="let enrichedCaseNote of (caseNoteList); let i = index">
        <mat-accordion multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-description>
              <table>
                <colgroup>
                  <col style="width:15%">
                  <col style="width:45%">
                  <col style="width:15%">
                  <col style="width:15%">
                  <col style="width:10%">
                </colgroup>
                <tr>
                  <td id="caseNoteTypeLabel{{i}}">{{ 'caseNote.columns.type' | translate }}</td>
                  <td id="caseNoteSubjectLabel{{i}}">{{ 'caseNote.columns.subject' | translate }}</td>
                  <td id="caseNoteDateLabel{{i}}">{{ 'caseNote.columns.noteDate' | translate }}</td>
                  <td id="caseNoteEnteredByLabel{{i}}">{{ 'caseNote.columns.enteredBy' | translate }}</td>
                  <td id="caseNotePriorityLabel{{i}}">{{ 'caseNote.columns.priority' | translate }}</td>
                </tr>
                <tr class="note-detail">
                  <td id="caseNoteTypeValue{{i}}">{{enrichedCaseNote?.caseNote?.typeDescription}}</td>
                  <td id="caseNoteSubjectValue{{i}}">{{enrichedCaseNote?.caseNote?.subject || 'N/A'}}</td>
                  <td id="caseNoteDateValue{{i}}">{{enrichedCaseNote?.caseNote?.date || 'N/A'}}</td>
                  <td id="caseNoteEnteredByValue{{i}}">{{enrichedCaseNote?.caseNote?.userFullName || 'N/A'}}</td>
                  <td id="caseNotePriorityValue{{i}}">{{enrichedCaseNote?.caseNote?.priorityDescription || 'N/A'}}</td>
                </tr>
              </table>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mccss-case-note-history [parsedNoteHistories]="enrichedCaseNote?.parsedNoteHistory" [caseNoteId]="i">
          </mccss-case-note-history>
        </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </mat-accordion>
  </div>

</section>
