import { Injectable, isDevMode } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';
import { share, first, tap, skip } from 'rxjs/operators';
import { AppInputServiceService } from '../app-input-service/app-input-service.service';
import { PlanContentResponse } from './PlanContentResponse';
import { PlanContentService } from './plan-content.service';

@Injectable({providedIn: 'root'})
export class PlanContentResolver
  implements Resolve<Observable<PlanContentResponse>>
{
  /**
   * indicates if resolve() was already executed
   */
  private wasInvoked = false;
  /**
   * ActivatedRouteSnapshot object
   */
  private route: ActivatedRouteSnapshot;

  constructor(
    private pcs: PlanContentService,
    private appInputService: AppInputServiceService
  ) {}

  /**
   * IMPORTANT: Can't cancel navigation. bug in Angular https://github.com/angular/angular/issues/21961  !!!!!!!!!!!!!!!!
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PlanContentResponse> {
    if (this.wasInvoked) {
      // if the resolver was already executed
      return null; // stop further execution
    }

    // tell the servides to use mock response if needed for development
    this.pcs.useMockResponse = this.appInputService.useMockResponse;
    return this.pcs.getData(this.appInputService.caseReference).pipe(
      share(),
      tap(() => {
        this.wasInvoked = true; // stop resolver from executing again when user navigates to the same route again
      })
    ); // call back-end API
  }
}
