import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'mccss-skip-to-main',
  templateUrl: './skip-to-main.component.html',
  styleUrls: ['./skip-to-main.component.scss'],
})
export class SkipToMainComponent {
  /**
   * Holds link text that is configurable by parent component
   */
  @Input() linkText: string = null;
  /**
   * Holds selector of an element which will be focused after click, enter or space key.
   * Selector is specified by parent component.
   */
  @Input() selector: string = null;
  /**
   * Reference to the link element
   */
  @ViewChild('skip') link: ElementRef<HTMLElement>;

  constructor() {}

  /**
   * Focus In event handler
   * @param event
   */
  onFocusIn(event: any): void {
    this.link.nativeElement.classList.remove('sr-only'); // remove class from link
  }

  /**
   * Focus Out event handler
   * @param event
   */
  onFocusOut(event: any): void {
    this.link.nativeElement.classList.add('sr-only'); // add class to link
  }

  /**
   * Keyboard event handler
   * @param event
   */
  onKeyDown(event: KeyboardEvent) {
    // only skip to main if Enter or Space is pressed
    if (event.key === 'Enter' || event.key === ' ') {
      this.skipToMain();
    }
  }

  /**
   * Selects an element to which the focus will be applied.
   */
  public skipToMain(): void {
    const skipToHeader = document.querySelector(this.selector) as any;
    skipToHeader.focus();
    skipToHeader.scrollIntoView();
  }
}
