import { Component } from '@angular/core';
import { CaseworkerService } from '../../../../services/caseworker/caseworker.service';

@Component({
  selector: 'mccss-eocase-owner-profile',
  templateUrl: './eocase-owner-profile.component.html',
  styleUrls: ['./eocase-owner-profile.component.scss'],
})
export class EOCaseOwnerProfileComponent {
  constructor(public cws: CaseworkerService) {}
}
