import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, first, takeUntil } from 'rxjs/operators';
import { PlanOutcomeResponse } from './PlanOutcomeResponse';
import { EAPOutcome } from './EAPOutcome';
import { TranslateService } from '@ngx-translate/core';
import { StringHelperService } from '../shared/StringHelper.service';
import { BaseAPIService } from '../shared/base-api/base-api.service';

/**
 * PlanSummaryServiceLoader to be used by importing module (ex: app module).
 * @param s PlanSummaryService injection
 * @param environments environments/environment.ts
 */
export function PlanOutcomeServiceLoader(
  s: PlanOutcomeService,
  environments: any
) {
  return () => s.setEnvApiConf(environments.api.planOutcome);
}

@Injectable({
  providedIn: 'root',
})
export class PlanOutcomeService extends BaseAPIService {
  /*
   * Destruction observable
   */
  public readonly destroy$: Subject<boolean> = new Subject<boolean>();
  /**
   * Holds PlanOutcomeResponse object for internal processing ONLY.
   * This property MUST be private.
   */
  private _planOutcomeResponse = new BehaviorSubject<PlanOutcomeResponse>(
    PlanOutcomeService.init()
  );
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public planOutcomeResponse$: Observable<PlanOutcomeResponse> =
    this._planOutcomeResponse.asObservable();
  /**
   * contains a list of objects that were expanded in UI table
   */
  public expandedElement: EAPOutcome[] = [];
  /**
   * This is a flag value (yes) which is set by translation servie.
   * Flag value is used to replace some property values of EAPOutcome object
   */
  public yes: string;
  /**
   * This is a flag value (yes) which is set by translation servie.
   * Flag value is used to replace some property values of EAPOutcome object
   */
  public no: string;
  /**
   * Aria text for close button located on the plan content page.
   */
  public closeBtnAria: string;
  /**
   * Aria text for open button located on the plan content page.
   */
  public openBtnAria: string;

  constructor(
    public http: HttpClient,
    private translate: TranslateService,
    private strHelper: StringHelperService
  ) {
    super();
    this.subscribeToTranslations();
    this.subscribeToButtonTranslation();
  }

  public static init(): PlanOutcomeResponse {
    const ps: PlanOutcomeResponse = {
      eapOutcomeList: [] as EAPOutcome[],
      errorResponse: null,
      descriptionMap: {},
    } as PlanOutcomeResponse;
    return ps;
  }

  private subscribeToButtonTranslation(): void {
    this.translate
      .get('planContentList.columns.iconAriaLabel.open')
      .subscribe((txt: string) => {
        this.openBtnAria = txt;
      });
    this.translate
      .get('planContentList.columns.iconAriaLabel.close')
      .subscribe((txt: string) => {
        this.closeBtnAria = txt;
      });
  }

  private subscribeToTranslations(): void {
    this.translate
      .get('generic.yes')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: string) => {
        this.yes = res;
      });
    this.translate
      .get('generic.no')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: string) => {
        this.no = res;
      });
  }

  checkExpanded(element): boolean {
    let flag = false;
    this.expandedElement.forEach((e) => {
      if (e === element) {
        flag = true;
      }
    });
    return flag;
  }

  pushPopElement(element) {
    const index = this.expandedElement.indexOf(element);
    if (index === -1) {
      this.expandedElement.push(element);
    } else {
      this.expandedElement.splice(index, 1);
    }
  }

  public getLastPlanOutcomeResponseValue(): PlanOutcomeResponse {
    return this._planOutcomeResponse.value;
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(caseReference: number): string {
    const url = this.useMockResponse
      ? this.conf.mockURL
      : this.conf.path + '/' + caseReference.toString();
    return url;
  }

  /**
   * Load data from back end
   * @param apiPath - path to GET method URL
   */
  getData(caseReference: number) {
    this.http
      .get<PlanOutcomeResponse>(this.computeApiURL(caseReference), {
        headers: this.getHttpOptions(),
        withCredentials: true,
      })
      .pipe(
        first(), // obtain only 1st emitted data
        map((data) => {
          this.massageData(data, caseReference);
          this._planOutcomeResponse.next(data);
        })
      )
      .subscribe();
  }

  /**
   * Massages data for each item in the eapOutcomeList[] property by using
   * keys from each EAPOutcome item and looking up their description in the
   * PlanOutcomeResponse.descriptionMap property
   * @param por PlanOutcomeResponse object
   */
  private massageData(por: PlanOutcomeResponse, caseReference: number): void {
    if (this.responseHasData(por)) {
      por.eapOutcomeList.forEach((v) => {
        v.caseReference = caseReference.toString();
        this.massagePrimitiveData(v, por.descriptionMap);
        this.massageFlags(v);
        this.massageDates(v);
      });
    } else {
      throw new Error(this.conf.appError['apiResponseMissingData']);
    }
  }

  /**
   * Checks if there is a response and resposne has required properties
   * @param pcr @see {PlanHomeResponse} API response
   * @returns true if response has data; false otherwise
   */
  private responseHasData(por: PlanOutcomeResponse): boolean {
    return por && por.eapOutcomeList && por.descriptionMap ? true : false;
  }

  /**
   * Maps IDs/Codes to string descriptions stored in the descriptionMap property.
   * @param v @see {EAPOutcome} object
   * @param m {{ [key: string]: any }} - description map with lookup values
   */
  public massagePrimitiveData(v: EAPOutcome, m: { [key: string]: any }): void {
    const addDescriptionTo = (obj) => Object.keys(obj).reduce((prev, cur) => {
      prev[`${cur + "Description"}`] = undefined;
      return prev;
    }, {});

    const findDescriptionFromMap = (obj, map) => Object.keys(obj).reduce((prev, curr)=> {
      const desc = map[v[`${curr.replace("Description", "")}`]];
      if (desc){
        prev[curr] = desc;
      }
      return prev;
    }, {})

    const descMap = findDescriptionFromMap(addDescriptionTo(v), m);

    Object.keys(descMap).forEach(desc => {
      v[desc] = descMap[desc];
    })
  }

  /**
   * Massages all flags/indicators with 'yes' or 'no' values.
   * @param v @see {EAPOutcome} object
   */
  private massageFlags(v: EAPOutcome): void {
    v.eapMilestoneAchievedINDDescription =
      v.eapMilestoneAchievedIND === '1' ? this.yes : this.no;
    v.eapReSubmitMilestoneINDDescription =
      v.eapReSubmitMilestoneIND === '1' ? this.yes : this.no;
    v.eapCollectionNoticeINDDescription =
      v.eapCollectionNoticeIND === '1' ? this.yes : this.no;
  }

  /**
   * Massages all dates and generates dates as readable strings for screen readers
   * @param v @see {EAPOutcome} object
   */
  private massageDates(v: EAPOutcome): void {
    this.massageEndDate(v);
    this.massageExpectCompleteDate(v);
    this.massageScheduledStartDate(v);
    this.massageStartDate(v);
    this.massageEapLastEmpDate(v);
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPOutcome} object
   */
  private massageEndDate(v: EAPOutcome): void {
    v.endDate = this.strHelper.getDateToNAifNull(v.endDate);
    v.endDateScreenReader = this.strHelper.getScreenReaderDate(v.endDate);
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPOutcome} object
   */
  private massageExpectCompleteDate(v: EAPOutcome): void {
    v.expectCompleteDate = this.strHelper.getDateToNAifNull(
      v.expectCompleteDate
    );
    v.expectCompleteDateScreenReader = this.strHelper.getScreenReaderDate(
      v.expectCompleteDate
    );
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPOutcome} object
   */
  private massageScheduledStartDate(v: EAPOutcome): void {
    v.scheduledStartDate = this.strHelper.getDateToNAifNull(
      v.scheduledStartDate
    );
    v.scheduledStartDateScreenReader = this.strHelper.getScreenReaderDate(
      v.scheduledStartDate
    );
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPOutcome} object
   */
  private massageStartDate(v: EAPOutcome): void {
    v.startDate = this.strHelper.getDateToNAifNull(v.startDate);
    v.startDateScreenReader = this.strHelper.getScreenReaderDate(v.startDate);
  }

  /**
   * Massages dates and generates dates as readable strings for screen readers
   * @param v @see {EAPOutcome} object
   */
  private massageEapLastEmpDate(v: EAPOutcome): void {
    v.eapLastEmpDate = this.strHelper.getDateToNAifNull(v.eapLastEmpDate);
    v.eapLastEmpDateScreenReader = this.strHelper.getScreenReaderDate(v.eapLastEmpDate);
  }

  /**
   * generate random number
   * @param min
   * @param max
   */
  public getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    // Generate a secure random number between 0 and 1
    const random = crypto.getRandomValues(new Uint32Array(1))[0] / (2 ** 32 - 1);
    return Math.floor(random * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

}
