import { Injectable } from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class PageTitleStrategyService extends TitleStrategy{

  constructor(private readonly title: Title, private translator: TranslateService) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const key = this.buildTitle(snapshot);
    if(key !== undefined){
      const translation = this.translator.instant(key);
      this.title.setTitle(translation);
    }
  }
}
