import { Component } from '@angular/core';

@Component({
  selector: 'mccss-case-note',
  templateUrl: './case-note.component.html',
  styleUrls: ['./case-note.component.scss']
})
export class CaseNoteComponent {
  constructor() {
    // NOSONAR
  }

}
