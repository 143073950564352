import {Component} from '@angular/core';
import {AppInputServiceService} from "../../../../services/app-input-service/app-input-service.service";
import {EnrichedCaseNote} from "../../../../services/cams2sams/EnrichedCaseNote";

@Component({
  selector: 'mccss-case-note-list',
  templateUrl: './case-note-list.component.html',
  styleUrls: ['./case-note-list.component.scss']
})
export class CaseNoteListComponent {

  caseNoteList: EnrichedCaseNote[]
  constructor(
    public appInputService: AppInputServiceService
  ) {
    this.caseNoteList = appInputService.caseNoteList
  }
}
