import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  HostListener,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryDetails } from '../../../services/history/HistoryDetails';
import { HistoryResponse } from '../../../services/history/HistoryResponse';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mccss-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss'],
})
export class HistoryDialogComponent {
  public dataSource1: MatTableDataSource<HistoryDetails>;

  public columnsToDisplay = ['oldValue', 'updatedOn', 'updateUser'];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      /**
       * Header label (ngxTranslate key) to be used in the HistoryDialog component table.
       */
      headerKey: string;
      /**
       * HistoryService response
       */
      history: HistoryResponse;
    },
    private mdDialogRef: MatDialogRef<HistoryDialogComponent>
  ) {
    this.dataSource1 = new MatTableDataSource<HistoryDetails>(
      this.data.history.eapHistoryDetails.eapDataTypeChangeHistories
    );
  }

  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
}
