import { Component, OnInit } from '@angular/core';
import { PlanHomeService } from '../../../../services/plan-home/plan-home.service';
import { HistoryDataType } from '../../../../services/history/HistoryDataType';
import { PropName } from '../../../../services/history/PropName';

@Component({
  selector: 'mccss-eapemployment-goals',
  templateUrl: './eapemployment-goals.component.html',
  styleUrls: ['./eapemployment-goals.component.scss'],
})
export class EAPEmploymentGoalsComponent {
  /**
   * Expose enum to template
   */
  historyDataType = HistoryDataType;
  /**
   * Expose enum to template
   */
  propName = PropName;
  constructor(public phs: PlanHomeService) {}
}
