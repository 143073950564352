<a
  *ngIf="isHistoryVisible"
  (click)="getHistory($event)"
  (keydown)="keyDown($event)"
  mat-icon-button
  matTooltip="click to see history changes"
  matTooltipClass="history-icon-tooltip"
  matTooltipShowDelay="1000"
  aria-label="click to see history changes"
  tabIndex="0"
>
  <mat-icon
    [ngClass]="{ 'history-icon': true, 'is-clicked': isClicked }"
  >
    {{ 'history' }}
  </mat-icon>
</a>
