import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { H1TitleComponent } from './h1-title.component';

@NgModule({
  declarations: [H1TitleComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [H1TitleComponent],
})
export class H1TitleModule {}
