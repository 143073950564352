<a
  (click)="skipToMain()"
  (keydown)="onKeyDown($event)"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn($event)"
  class="skip-to-main sr-only"
  tabindex="0"
  #skip
  >{{ this.linkText }}</a
>
