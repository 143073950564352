import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { PlanHomeResolver } from '../../../services/plan-home/plan-home.resolver';

import { EAPHomeModule } from '../../feature/home/eaphome/eaphome.module';
import { EAPEmploymentGoalsModule } from '../../feature/home/eapemployment-goals/eapemployment-goals.module';
import { EAPEmploymentOutcomesModule } from '../../feature/home/eapemployment-outcomes/eapemployment-outcomes.module';
import { H1TitleModule } from '../../feature/shared/h1-title/h1-title.module';

@NgModule({
  declarations: [HomeComponent],
  providers: [PlanHomeResolver],
  imports: [
    CommonModule,
    EAPHomeModule,
    EAPEmploymentOutcomesModule,
    EAPEmploymentGoalsModule,
    H1TitleModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
