import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, first, share } from 'rxjs/operators';
import { HistoryResponse } from './HistoryResponse';
import { AppInputServiceService } from '../app-input-service/app-input-service.service';
import { HistoryRequest } from './HistoryRequest';
import { StringHelperService } from '../shared/StringHelper.service';

/**
 * HistoryServiceLoader to be used by importing module (ex: app module).
 * @param s HistoryService injection
 * @param appInputService AppInputServiceService injection
 * @param environments environments/environment.ts
 */
export function HistoryServiceLoader(
  s: HistoryService,
  appInputService: AppInputServiceService,
  environments: any
) {
  // tell the servides to use mock response if needed for development
  s.useMockResponse = appInputService.useMockResponse;
  return () => s.setApiUrl(environments.api.historyService.path);
}

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  /**
   * Indicates if the service should call real API or use mock data that is provided
   * by a .json file in the /assets/services/mock folder
   */
  public useMockResponse = false;
  /**
   * Mock URL used for development purposes. The service will use a static mock
   * response stored in the .json file located in the /assets/services/mock folder
   */
  public mockURL = '/assets/services/mock/historyResponse.json';

  /**
   * Back end API URL; initialized using setApiUrl(url: string) by
   * HistoryServiceLoader(historySerive, appInputService, environments: any)
   */
  private apiURL: string;
  /**
   * Holds HistoryResponse object for internal processing ONLY.
   * This property MUST be private.
   */
  private _historyResponse = new BehaviorSubject<HistoryResponse>(null);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public historyResponse$: Observable<HistoryResponse> =
    this._historyResponse.asObservable();

  /**
   * selectedProperty key for which we pull the history data.
   * Used by UI subscriber components to know for which property
   * we just pulled the history.
   * This property MUST be private.
   */
  private _selectedProperties = new BehaviorSubject<Array<string>>([]);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public selectedProperty$: Observable<Array<string>> =
    this._selectedProperties.asObservable();

  constructor(public http: HttpClient, private strHelper: StringHelperService) {
  }

  public setApiUrl(url: string): void {
    this.apiURL = url;
  }

  /**
   * Computes API URL based on useMockResponse flag
   * If 'useMockResponse' is set to true, the service
   * will use .json mock file URL instead of API URL
   */
  public computeApiURL(r: HistoryRequest): string {
    const url = this.useMockResponse
      ? this.mockURL
      : this.apiURL +
      '/' +
      r.dataType +
      '/casereference/' +
      r.caseRef +
      '/fieldReference/' +
      r.prop;
    return url;
  }

  /**
   * Load data from back end
   * @param apiPath - path to GET method URL
   */
  getData(historyRequest: HistoryRequest, history: HistoryResponse): Observable<HistoryResponse> {
    const resp = of(history)
      .pipe(
        share(),
        first(), // obtain only 1st emitted data
        map((data) => {
          this.setSelectedProperty(historyRequest.prop);
          this.cleanRedundantHistory(data);
          this.massageData(data, historyRequest.isDate);
          return data;
        })
      );
    return resp;
  }

  /**
   * Removes redundant history records in sequence by comparing the earliest
   * record to the next - if there is no change in value, we remove the next record,
   * if there is change, then we keep the next record. After comparison, if there was no
   * change, we compare the current record to the next record agin until the end of list.
   * @param h {HistoryResponse} response object from history service
   */
  private cleanRedundantHistory(h: HistoryResponse): void {
    // note, we need to constantly check array.length because we may delete an element(s) from it
    for (
      let i = 0;
      i < h.eapHistoryDetails.eapDataTypeChangeHistories.length;
      i++
    ) {
      const nextIndex = i + 1; // set next index
      // if next index exists
      if (nextIndex < h.eapHistoryDetails.eapDataTypeChangeHistories.length) {
        //if current object's property equals to next object's property
        if (
          h.eapHistoryDetails.eapDataTypeChangeHistories[i].newValue ===
          h.eapHistoryDetails.eapDataTypeChangeHistories[nextIndex].newValue
        ) {
          // delete next index
          h.eapHistoryDetails.eapDataTypeChangeHistories.splice(nextIndex, 1);
          i--; // keep the current index during next iteration
        }
      }
    }
  }

  /**
   * Saves selectedProperty key for which we pull the history data.
   * Used by UI to toggle class in order to highlight the history icon which was last clicked
   * @param selectedProperty maps to HistoryRequest.prop @see {HistoryRequest}
   */
  private setSelectedProperty(selectedProperty: string): void {
    const temp = this._selectedProperties.value;
    temp.push(selectedProperty);
    this._selectedProperties.next(temp);
  }

  private massageData(h: HistoryResponse, valueIsDate: boolean): void {
    h.eapHistoryDetails.eapDataTypeChangeHistories.forEach((v) => {
      if (valueIsDate) {
        // if value is date
        v.newValueIsDate = valueIsDate;
        v.newValueDescription = v.newValue;
        v.newValueScreenReader = this.strHelper.getScreenReaderDate(v.newValue);
      } else {
        const temp = h.descriptionMap[v.newValue];
        // if value is lookup code, assign lookup description, else use actual value
        v.newValueDescription = temp ? temp : v.newValue;
      }

      v.updatedTimestampScreenReader = this.strHelper.getScreenReaderTimestamp(
        v.updatedTimestamp
      );
    });
  }

  /**
   * Returns newly created httpOptions object that is used by POST request to back-end.
   * Object contains HttpHeaders for application/json, etc.
   */
  private getHttpOptions(): any {
    const timestamp =
      new Date().toISOString().replace('T', ' ').replace('Z', '') + '000';
    const headers = new HttpHeaders()
      .set('x-bil-source-system', 'BIL')
      .set('x-bil-target-system', 'CAMS')
      .set('x-bil-req-timestamp', timestamp);

    return headers;
  }
}
