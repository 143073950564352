import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TabNavigationComponent } from './tab-navigation.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
  declarations: [TabNavigationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true
    }),
    MatTabsModule,
    MatDividerModule
  ],
  exports: [TabNavigationComponent],
})
export class TabNavigationModule {}
