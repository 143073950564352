<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>

<div mat-dialog-content>
  <p class="dialog-message" [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions>

  <button
    mat-raised-button
    color="primary"
    (click)="confirm()"
  >
    {{ data.confirmText }}
  </button>

  <button
    *ngIf="data.cancelText"
    mat-raised-button
    (click)="cancel()"
  >
    {{ data.cancelText }}
  </button>
</div>
