import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject, Observable, share } from "rxjs";
import { map, first } from 'rxjs/operators';
import { SAMSUserProfile } from './SAMSUserProfile';
import { URLSearchParams } from "../browser-patch/UrlSearchParams";

/**
 * SamsUserServiceLoader to be used by importing module (ex: app module).
 * @param s SamsUserServiceLoader injection
 * @param environments environments/environment.ts
 */
export function SamsUserServiceLoader(s: SamsUserService, environments: any) {
  s.setApiUrl(environments.api.samsUserService.path);
  return () => s.getData();
}

@Injectable({
  providedIn: 'root'
})
export class SamsUserService {
  /**
   * Back end API URL; initialized using setApiUrl(url: string) by
   * SamsUserServiceLoaderLoader(s: SamsUserServiceLoader, environments: any)
   */
  private apiURL: string;
  /**
   * Holds SAMSUserProfile object for internal processing ONLY.
   * This property MUST be private.
   */
  private _samsUserProfile = new BehaviorSubject<Partial<SAMSUserProfile>>(null);
  /**
   * Expose BehaviorSubject response to external subscribers as an Observable
   */
  public samsSecurity$: Observable<
    Partial<SAMSUserProfile>
  > = this._samsUserProfile.asObservable();

  public mockURL = '/assets/services/mock/userProfileResponse.json';

  constructor(public http: HttpClient) {
  }

  public setApiUrl(url: string): void {
    const useMockResponse = !!+URLSearchParams.getParam('useMockResponse')
    this.apiURL = useMockResponse ? this.mockURL : url;
  }

  /**
   * Load data from back end
   * @param apiPath - path to GET method URL
   */
  getData() {
    const r = this.http
      .get<SAMSUserProfile>(this.apiURL, {
        headers: this.getHttpOptions(),
        withCredentials: true
      })
      .pipe(
        share(),
        first(), // obtain only 1st emitted data
        map((data: any) => {
          this._samsUserProfile.next(data);
          return data;
        })
      );
    r.subscribe();
    return r;
  }

  /**
   * Returns newly created httpOptions object that is used by POST request to back-end.
   * Object contains HttpHeaders for application/json, etc.
   */
  private getHttpOptions(): any {
    const timestamp =
      new Date()
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '') + '000';
    const headers = new HttpHeaders()
      //.set('x-bil-gateway', '123');
      .set('x-bil-source-system', 'BIL')
      .set('x-bil-target-system', 'CAMS')
      .set('x-bil-req-timestamp', timestamp);

    return headers;
  }
}
