import { Component } from '@angular/core';
import { first } from "rxjs/operators";
import { filter } from "rxjs";
import { SamsUserService } from "../../../services/sams-user/sams-user.service";

@Component({
  selector: 'mccss-logged-in-user-icon',
  templateUrl: './logged-in-user-icon.component.html',
  styleUrls: ['./logged-in-user-icon.component.scss'],
})
export class LoggedInUserIconComponent {
  public initials = '';
  constructor(private samsUserService: SamsUserService) {
    this.samsUserService.samsSecurity$.pipe(
      first(profile => !!profile),
      filter(profile => !!profile.firstName && !!profile.lastName)
    ).subscribe((p) => {
      const getInitial = (name: string) => name.charAt(0).toUpperCase();
      this.initials = getInitial(p.firstName) + getInitial(p.lastName);
    });
  }
}
