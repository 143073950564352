<section
  class="container"
  role="region"
  aria-labelledby="eapEmploymentGoalsTitle"
>
  <mccss-h2-title [id]="'eapEmploymentGoalsTitle'" [title]="'eapHome.homeEmploymentGoals' | translate"></mccss-h2-title>
  <div
    class="form-control-container"
    *ngIf="(phs.planHomeResponse$ | async).eapHome as phr"
  >
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.goalNameDescription' | translate"
        [data]="phr.goalNameDescription"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.goalName',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.goalNameDescription'
        }"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.empGoalNumberOfHoursWorked' | translate"
        [data]="phr.empGoalNumberOfHoursWorked"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.empGoalNumberOfHoursWorked',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.empGoalNumberOfHoursWorked'
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
    <mccss-mat-row-two-columns>
      <mccss-eap-row
        left
        [label]="'eapHome.empGoalNocCDDescription' | translate"
        [data]="phr.empGoalNocCDDescription"
      ></mccss-eap-row>
      <mccss-eap-row
        right
        [conf]="{ fxFlex: { label: 70, data: 30 } }"
        [label]="'eapHome.empGoalWagePerHourAmount' | translate"
        [data]="phr.empGoalWagePerHourAmount"
        [history]="{
          dataType: historyDataType.PlanHomeApi,
          prop: propName.eapHome + '.empGoalWagePerHourAmount',
          caseRef: phr.caseReference,
          headerKey: 'eapHome.empGoalWagePerHourAmount'
        }"
      ></mccss-eap-row>
    </mccss-mat-row-two-columns>
  </div>
</section>
