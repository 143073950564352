import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SkipToMainComponent } from './skip-to-main.component';

@NgModule({
  declarations: [SkipToMainComponent],
  imports: [CommonModule, TranslateModule.forChild({ extend: true })],
  exports: [SkipToMainComponent],
})
export class SkipToMainModule {}
