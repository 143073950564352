// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    genericError: {
      noApiConf: 'apiErrors.genericError.noApiConf',
    },
    planSummary: {
      method: 'GET',
      path: '/api/eap/plan-summary', // https://eap-dev.apps.uat.cf.az.cihs.gov.on.ca
      mockURL: '/assets/services/mock/planSummaryResponse_Accepted2.json',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.planSummaryService.404',
        ngxTranslateKeyError500: 'apiErrors.planSummaryService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
      appError: {
        apiResponseMissingData:
          'apiErrors.planSummaryService.apiResponseMissingData',
      },
    },
    planOutcome: {
      path: '/api/eap/outcome',
      mockURL: '/assets/services/mock/outcomeResponse2.json',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.planOutecomeService.404',
        ngxTranslateKeyError500: 'apiErrors.planOutecomeService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
      appError: {
        apiResponseMissingData:
          'apiErrors.planOutecomeService.apiResponseMissingData',
      },
    },
    planContent: {
      path: '/api/eap/plan-content',
      mockURL: '/assets/services/mock/planContentResponse2.json',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.planContentService.404',
        ngxTranslateKeyError500: 'apiErrors.planContentService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
      appError: {
        apiResponseMissingData:
          'apiErrors.planContentService.apiResponseMissingData',
      },
    },
    planHome: {
      path: '/api/eap/plan-home',
      mockURL: '/assets/services/mock/planHomeResponse2.json',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.planHomeService.404',
        ngxTranslateKeyError500: 'apiErrors.planHomeService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
      appError: {
        apiResponseMissingData:
          'apiErrors.planHomeService.apiResponseMissingData',
      },
    },
    caseworker: {
      path: '/api/eap/caseworker/profile',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.caseWorkerService.404',
        ngxTranslateKeyError500: 'apiErrors.caseWorkerService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
    },
    cams2sams: {
      path: '/api/eap/samstocams/caselist',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.sams2camsService.404',
        ngxTranslateKeyError500: 'apiErrors.sams2camsService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
        ngxTranslateKeyErrorNoEAP: 'apiErrors.sams2camsService.NoEAP',
        ngxTranslateKeyErrorAllEAPClosed:
          'apiErrors.sams2camsService.AllEAPClosed',
      },
      activeEAPCodes: 'CS1, CS4, CS17',
    },
    samsUserService: {
      path: '/api/eap/userprofile',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError400: 'apiErrors.samsUserService.400',
        ngxTranslateKeyError404: 'apiErrors.samsUserService.404',
        ngxTranslateKeyError500: 'apiErrors.samsUserService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
    },
    historyService: {
      path: '/api/eap/history/datatype',
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.historyService.404',
        ngxTranslateKeyError500: 'apiErrors.historyService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',

      },
    },
    historyChangeService: {
      path: '/api/eap/history/changehistory/datatype',
      isTurnedOff: false,
      customError: {
        ngxTranslateKeyError401: 'apiErrors.genericError.401',
        ngxTranslateKeyError404: 'apiErrors.historyChangeService.404',
        ngxTranslateKeyError500: 'apiErrors.historyChangeService.500',
        ngxTranslateKeyError503: 'apiErrors.genericError.503',
      },
      appError: {
        404: {
          suppress: true,
        },
        ngxNoRouteConfig: 'apiErrors.historyChangeService.noRouteConfig',
        ngxNoDataType: 'apiErrors.historyChangeService.noDataType',
      },
      additionalData: {
        mockUrlPlanHome:
          '/assets/services/mock/historyChangeResponse_PlanHome.json',
        mockUrlPlanContent:
          '/assets/services/mock/historyChangeResponse_PlanContent.json',
        mockUrlPlanSummary:
          '/assets/services/mock/historyChangeResponse_PlanSummary.json',
        mockUrlPlanOutcome:
          '/assets/services/mock/historyChangeResponse_PlanOutcome.json',
      },
    },
  },
  appInsights: {
    instrumentationKey: '',
  },
  spinnerTimeInMS: 1000,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
