<script src="../case-note-history/case-note-history.component.ts"></script>
<section class="container" role="region" attr.aria-label="{{ 'caseNote.title' | translate }}">

  <div class="form-control-container">
    <mat-accordion togglePosition="before">
      <ng-container *ngFor="let enrichedCaseNote of (caseNoteList); let i = index">
        <mat-accordion multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-description>
                <div id="detailsTableDescription" class="visually-hidden">
                  This table displays case note details, including type, subject, date, entered by, and priority.
                </div>

                <table class="table-header-left" aria-describedby="detailsTableDescription">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:45%">
                    <col style="width:15%">
                    <col style="width:15%">
                    <col style="width:10%">
                  </colgroup>
                  <thead>
                  <tr>
                    <th id="caseNoteTypeHeader{{i}}">{{ 'caseNote.columns.type' | translate }}</th>
                    <th id="caseNoteSubjectHeader{{i}}">{{ 'caseNote.columns.subject' | translate }}</th>
                    <th id="caseNoteDateHeader{{i}}">{{ 'caseNote.columns.noteDate' | translate }}</th>
                    <th id="caseNoteEnteredByHeader{{i}}">{{ 'caseNote.columns.enteredBy' | translate }}</th>
                    <th id="caseNotePriorityHeader{{i}}">{{ 'caseNote.columns.priority' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="note-detail">
                    <td id="caseNoteTypeValue{{i}}">{{enrichedCaseNote?.caseNote?.typeDescription}}</td>
                    <td id="caseNoteSubjectValue{{i}}">{{enrichedCaseNote?.caseNote?.subject || 'N/A'}}</td>
                    <td id="caseNoteDateValue{{i}}">{{enrichedCaseNote?.caseNote?.date || 'N/A'}}</td>
                    <td id="caseNoteEnteredByValue{{i}}">{{enrichedCaseNote?.caseNote?.userFullName || 'N/A'}}</td>
                    <td id="caseNotePriorityValue{{i}}">{{enrichedCaseNote?.caseNote?.priorityDescription || 'N/A'}}</td>
                  </tr>
                  </tbody>
                </table>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mccss-case-note-history [parsedNoteHistories]="enrichedCaseNote?.parsedNoteHistory" [caseNoteId]="i">
            </mccss-case-note-history>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </mat-accordion>
  </div>

</section>
