import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogMsgComponent } from './dialog-msg.component';
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [DialogMsgComponent],
  exports: [DialogMsgComponent],
})
export class DialogMsgModule {}
