import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DateSpanComponent } from './date-span.component';

@NgModule({
  declarations: [DateSpanComponent],
  imports: [CommonModule, TranslateModule.forChild({ extend: true })],
  exports: [DateSpanComponent],
})
export class DateSpanModule {}
