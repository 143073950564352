import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { EAPEmploymentGoalsComponent } from './eapemployment-goals.component';

import { EapRowModule } from '../../shared/eap-row/eap-row.module';
import { MatRowTwoColumnsModule } from '../../shared/mat-row-two-columns/mat-row-two-columns.module';
import { H2TitleComponent } from "@shared/h2-title/h2-title.component";

@NgModule({
  declarations: [EAPEmploymentGoalsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      // loader: {provide: TranslateLoader, useClass: CustomLoader},
      extend: true
    }),
    EapRowModule,
    MatRowTwoColumnsModule,
    H2TitleComponent
  ],
  exports: [EAPEmploymentGoalsComponent],
})
export class EAPEmploymentGoalsModule {}
