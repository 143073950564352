import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DialogMsgService } from '../../components/feature/dialog-msg/dialog-msg.service';

import { AzureInsightService } from '@mccss/shared/monitor';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  translate: TranslateService;
  dialogMsgService: DialogMsgService;

  constructor(
    private injector: Injector,
    private azureInsightService: AzureInsightService
  ) {
    this.dialogMsgService = this.injector.get(DialogMsgService);
    this.translate = this.injector.get(TranslateService);
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      // Server errors are handled by HttpInterceptor
    } else {
      // Client Error
      // if error message is custom ngxTranslate key
      if (
        error.message.includes('apiErrors.') &&
        (error.message.includes('.401') ||
          error.message.includes('.400') ||
          error.message.includes('.404') ||
          error.message.includes('.500') ||
          error.message.includes('.NoEAP') ||
          error.message.includes('.AllEAPClosed'))
      ) {
        this.showErrorMsg(error.message); // show custom message
      } else {
        // if error is unknown
        this.showErrorMsg('generic.unknownError'); // show custom generic error
      }
    }
    this.azureInsightService.logException(error);
  }

  private showErrorMsg(errorMsgTranslationKey: string) {
    this.translate.get(errorMsgTranslationKey).subscribe((res: string) => {
      const data = {
        title: 'Error',
        message: res,
        confirmText: 'Ok',
      };
      this.dialogMsgService.showDialogDataSubject.next(data);
    });
  }
}
