import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { filter, Observable, switchMap } from "rxjs";
import { first, tap } from 'rxjs/operators';
import { AppInputServiceService } from '../app-input-service/app-input-service.service';
import { PlanHomeService } from './plan-home.service';
import { PlanHomeResponse } from './PlanHomeResponse';

@Injectable( {providedIn: 'root'})
export class PlanHomeResolver implements Resolve<Observable<PlanHomeResponse>> {
  /**
   * indicates if resolve() was already executed
   */
  private wasInvoked = false;
  /**
   * ActivatedRouteSnapshot object
   */
  private route: ActivatedRouteSnapshot;

  constructor(
    private pcs: PlanHomeService,
    private appInputService: AppInputServiceService
  ) {
  }

  /**
   * IMPORTANT: Can't cancel navigation. bug in Angular https://github.com/angular/angular/issues/21961  !!!!!!!!!!!!!!!!
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PlanHomeResponse> {
    if (this.wasInvoked) {
      // if the resolver was already executed
      return null; // stop further execution
    }

    // tell the servides to use mock response if needed for development
    this.pcs.useMockResponse = this.appInputService.useMockResponse;

    this.appInputService.caseReferenceNumber$.pipe(
      filter((refNumber) => !!refNumber),
      switchMap( refNumber => this.pcs.getData(refNumber)),
      first()
    ).subscribe();

    return this.pcs.planHomeResponse$.pipe(
      first(),
      tap(() => {
        this.wasInvoked = true; // stop resolver from executing again when user navigates to the same route again
      })
    );
  }
}
