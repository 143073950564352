import { HttpHeaders } from '@angular/common/http';
import { EnvApiConf } from './EnvApiConf';

export abstract class BaseAPIService {
  /**
   * Indicates if the service should call real API or use mock data that is provided
   * by a .json file in the /assets/services/mock folder
   */
  public useMockResponse = false;
  /**
   * Holds API configuraton specified by environment.ts file
   */
  public conf: EnvApiConf;

  /**
   * Sets configuration for the API service wich comes from environment.ts file
   * @param conf @see  {EnvApiConf}
   */
  public setEnvApiConf(conf: EnvApiConf): void {
    if (conf) {
      this.conf = conf;
      if (this.conf.isTurnedOff) {
        console.warn(
          this.conf.path +
            ' Service is turned off. Set environments.api.[YOUR SERVICE NAME].isTurnedOff=false to turn on service'
        );
      }
    } else {
      throw new Error('Environment configuration for API is not specified');
    }
  }

  /**
   * Returns newly created httpOptions object that is used by POST request to back-end.
   * Object contains HttpHeaders for application/json, etc.
   */
  public getHttpOptions(): any {
    const timestamp =
      new Date().toISOString().replace('T', ' ').replace('Z', '') + '000';
    const headers = new HttpHeaders()
      .set('x-bil-source-system', 'BIL')
      .set('x-bil-target-system', 'CAMS')
      .set('x-bil-req-timestamp', timestamp);

    return headers;
  }
}
